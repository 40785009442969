/*--------------------------------------------------------------------------\
    scs_tables2.js

    Description
      JavaScript utility to handle SCS tables a web context.

    Copyrights- (C) Copyright 2007-2020 Software Consulting Services, LLC (SCS).
\--------------------------------------------------------------------------*/

// Create a single global object to store all the global constants
var SORTABLE = {

  // Constants determining the type of column: checkbox, radio, textfield, etc.
  "COLUMN_TYPE_CHECKBOX" : 1,
  "COLUMN_TYPE_RADIO" : 2,
  "COLUMN_TYPE_TEXTFIELD" : 3,
  "COLUMN_TYPE_IMAGE" : 4,
  "COLUMN_TYPE_NORMAL" : 5,
  "COLUMN_TYPE_SELECTBOX" : 6,
  "COLUMN_TYPE_TOGGLE_ONE" : 7,
  "COLUMN_TYPE_TOGGLE_MULTIPLE" : 8,
  "COLUMN_TYPE_DATE" : 9,
  "COLUMN_TYPE_TIMESTAMP" : 10,
  "COLUMN_TYPE_FILE_SIZE" : 11,
  "COLUMN_TYPE_CURRENCY" : 12,
  "COLUMN_TYPE_BOOLEAN" : 13,
  "COLUMN_TYPE_COUNTDOWN" : 14,
  "COLUMN_TYPE_SIZE" : 15,
  "COLUMN_TYPE_PHONE_NUMBER" : 16,

  // Constants determining the type of column filter: none, date, number, etc.
  "FILTER_TYPE_NONE" : 0,
  "FILTER_TYPE_DATE" : 1,
  "FILTER_TYPE_NUMBER" : 2,
  "FILTER_TYPE_STRING" : 3,
  "FILTER_TYPE_SINGLE_SELECT" : 4,
  "FILTER_TYPE_MULTI_SELECT" : 5,
  "FILTER_TYPE_SIZE" : 6,
  "FILTER_TYPE_TIMESTAMP" : 7,

  // Constants to use inside unique identifiers
  "SELECT_ALL_PREFIX" : "selectall_",

  // Constants to store and manage the table header.
  "WORKING_OBJ_HIDE_SHOW" : "edit_a2",
  "WORKING_OBJ_FILTER_BOX" : "select_1",
  "WORKING_OBJ_FILTER_GROUPS1" : "select_2",
  "WORKING_OBJ_FILTER_TEXT" : "text_1",
  "WORKING_OBJ_MESSAGE_TEXT": "text_2",
  "WORKING_OBJ_FILTER_VALUE" : "filter_val",
  "WORKING_OBJ_LEFT_HEADER" : "edit_tdleft",

  // Constants to store and manage working filter objects.
  "WORKING_OBJ_FILTER" : "filter",
  "WORKING_OBJ_FILTER_TYPE" : "filter_type",
  "WORKING_OBJ_FILTER_COLUMN" : "filter_column",
  "WORKING_OBJ_FILTER_SIZE_CHOICE" : "filter_size",
  "WORKING_OBJ_FILTER_COMPARE" : "filter_compare",
  "WORKING_OBJ_FILTER_COMPARE_NOT" : "filter_compare_not",
  "WORKING_OBJ_FILTER_1" : "filter_1",
  "WORKING_OBJ_FILTER_2" : "filter_2",
  "WORKING_OBJ_FILTER_CONDITION" : "filter_condition",
  "WORKING_OBJ_FILTER_SPAN_1" : "filter_span1",
  "WORKING_OBJ_FILTER_SPAN_2" : "filter_span2",
  "WORKING_OBJ_FILTER_SPAN_3" : "filter_span3",
  "WORKING_OBJ_FILTER_SPAN_4" : "filter_span4",
  "WORKING_OBJ_FILTER_BR" : "filter_br",
  "WORKING_OBJ_FILTER_SP0" : "filter_sp0",
  "WORKING_OBJ_FILTER_SP1" : "filter_sp1",
  "WORKING_OBJ_FILTER_SP2" : "filter_sp2",

  "SPACER_NODE" : function() { return document.createTextNode("\u00a0\u00a0"); },

  "AUTO_REFRESH_DELAY" : 30000,

}

SortableColumn = function( columnType, columnNumber, isSortable, columnId,
                           columnTitle, isVisible, isHTML,
                           columnTitleGrid,
                           isLink, linkScript, linkColumn,
                           isMouse, mouseInfo, mouseOverFunc, mouseOutFunc,
                           mouseColumn, mouseDisplayColumn,
                           isStandardImage, altText, isListImage,
                           editLength, validValues, validIDs, validImages,
                           filterType, filterArray, 
                           wrapHeader, headerAlign, textAlign, colWidth,
                           useSortCol, filterListCol, hideColumn, allowWrap,
                           loadEvent, keyColumnID, selectAllCallback,
                           drilldown, drilldownCb, drilldownCol, sortColumn,
                           colMenuCallback, filterDataCallback,
                           defaultImage, timeCdToggle, sizeToggle, numLen ) {


  var getFunctionFromString = function( str, fallback ) {
    if ( isUndefinedOrNull( fallback ) ) { fallback = null };

    if ( ! isUndefinedOrNull( str ) && str != "" && typeof window[str] == "function" ) {
      return window[str];
    }
    else {
      return fallback;
    }
  };

  bindMethods( this );
  this.initSortableColumn();

  // Setup standard sortable column information.
  this.cType = columnType;

  this.cTimeCdToggle = timeCdToggle;
  this.cCdView = ( columnType == SORTABLE.COLUMN_TYPE_COUNTDOWN );

  this.cSizeToggle = sizeToggle;
  this.cInchesView = false;

  this.cNumber = columnNumber;
  this.cSortable = isSortable;
  this.cId = columnId;
  this.cTitle = columnTitle;
  this.cVisible = isVisible;
  this.cHTML = isHTML;
  this.cShowTitleGridView = columnTitleGrid;
  this.cKeyColumnId = keyColumnID;

  // Identify this sortable column as a link.
  this.cIsLink = isLink;
  this.cLinkScript = linkScript;
  this.cLinkColumn = linkColumn;

  // Identify this sortable column as having mouse over properties.
  this.cIsMouse = isMouse;
  this.cMouseInfo = mouseInfo;
  this.cMouseOverFunc = mouseOverFunc;
  this.cMouseOutFunc = mouseOutFunc;
  this.cMouseColumn = mouseColumn;
  this.cMouseDisplayColumn = mouseDisplayColumn;

  // Define the image properties of this sortable column.
  this.cIsStandardImage = isStandardImage;
  this.cAltText = altText;
  this.cIsListImage = isListImage;

  // Identify this sortable column as an editable field.
  this.cEditableLength = editLength;
  if(validValues.length > 0 && validImages.length > 0)
  {
    this.cValidValues = validValues.split(",");
    this.cValidImages = validImages.split(","); 
    if(this.cValidValues.length !== this.cValidImages.length)
    {
      this.cValidValues = [];
      this.cValidImages = [];
    }
  }
  else if ( validValues.length > 0 && validIDs.length > 0 )
  {
    this.cValidValues = validValues.split(",");
    this.cValidIDs = validIDs.split(",");
    if ( this.cValidValues.length !== this.cValidIDs.length )
    {
      this.cValidValues = [];
      this.cValidIDs = [];
    }
  }
  else
  {
    this.cValidValues = [];
    this.cValidImages = [];
  }


  // Used to distinguish single and double clicks
  this.sortTimer = null;

  // Identify the sortable filters allowed for this column.
  if ( ! isUndefinedOrNull( filterType ) ) {
    this.cFilterType = filterType;
    if ( ( ! isUndefinedOrNull( filterArray ) ) &&
         ( ( filterType == SORTABLE.FILTER_TYPE_SINGLE_SELECT ) ||
           ( filterType == SORTABLE.FILTER_TYPE_MULTI_SELECT ) ) ) {
      this.cFilterArray = filterArray;
    }
  }

  // Set header wrapping and alignment
  this.wrapHeader = wrapHeader;
  this.headerAlign = headerAlign;
  this.textAlign = textAlign;
  this.colWidth = colWidth;

  // Set select list and other sort options.
  this.useSortCol = useSortCol;
  this.filterListCol = filterListCol;

  // Set the hide column id.
  this.hideColumn = hideColumn;

  // Set data wrapping
  this.allowWrap = allowWrap;

  // Set LoadEvent to the function
  this.loadEvent = getFunctionFromString( loadEvent );
  this.selectAllCallback = selectAllCallback;

  // Drilldown feature
  this.drilldown = drilldown;
  this.drilldownCallback = drilldownCb;
  this.drilldownCol = drilldownCol;
  this.sortColumn = ( sortColumn != "" ) ? sortColumn : columnId;
  this.colMenuCallback = getFunctionFromString( colMenuCallback, noop );
  this.filterDataCallback = getFunctionFromString( filterDataCallback );
  this.defaultImage = defaultImage;

  this.numLen = ( isUndefinedOrNull( numLen ) || ( numLen < 1 ) ) ? 1 : numLen;
};

SortableColumn.prototype = {
  "initSortableColumn": function () {
    // Setup standard sortable column information.
    this.cType = SORTABLE.COLUMN_TYPE_NORMAL;
    this.cNumber = 0;
    this.cSortable = true;
    this.cId = "";
    this.cTitle = "";
    this.cVisible = true;
    this.cHTML = false;
    this.cShowTitleGridView = true;

    // Identify this sortable column as a link.
    this.cIsLink = false;
    this.cLinkScript = null;
    this.cLinkColumn = null;
    
    // Identify this sortable column as having mouse over properties.
    this.cIsMouse = false;
    this.cMouseInfo = "";
    this.cMouseOverFunc = null;
    this.cMouseOutFunc = null;
    this.cMouseColumn = null;
    this.cMouseDisplayColumn = null;

    // Define the image properties of this sortable column.
    this.cIsStandardImage = false;
    this.cAltText = "";
    this.cIsListImage = true;

    // Identify this sortable column as an editable field.
    this.cEditableLength = "20";
    this.cValidValues = [];
    this.cValidIDs = [];
    this.cValidImages = [];

    // Identify the sortable filters allowed for this column.
    this.cFilterType = SORTABLE.FILTER_TYPE_NONE;
    this.cFilterArray = null;

    // Set wrapping and centering
    this.wrapHeader = true;
    this.headerAlign = "left";
    this.textAlign = "left";
    this.colWidth = 0;

    // Set select list and other sort options.
    this.useSortCol = false;
    this.filterListCol = "";

    // Set the default hide column value.
    this.hideColumn = "";

    this.loadEvent = null;
    this.selectAllCallback = null;

    this.drilldown = false;
    this.drilldownCb = null;
    this.drilldownCol = null;
  },

  "getToggleValue" : function(img)
  {
    return this.cValidValues[this.cValidImages.indexOf(img)];
  },

  "getToggleImage" : function(val)
  {
    return this.cValidImages[this.cValidValues.indexOf(val + "")];
  },

  "attachLinkFunction": function( outputCell, inputRow, sortableTable, isLink ) {
    var linkFunc, idValue, keyValue;

    if ( ( isLink ) &&
         ( this.cIsLink ) &&
         ( ( this.cType == SORTABLE.COLUMN_TYPE_TEXTFIELD ) ||
           ( this.cType == SORTABLE.COLUMN_TYPE_BOOLEAN ) ||
           ( this.cType == SORTABLE.COLUMN_TYPE_IMAGE ) ||
           ( this.cType == SORTABLE.COLUMN_TYPE_NORMAL ) ||
           ( this.cType == SORTABLE.COLUMN_TYPE_SIZE ) ) ) {
      linkFunc = getGlobalFunction( this.cLinkScript );
      if ( linkFunc != null ) {
        idValue = inputRow[ sortableTable.tKeyId ];
        keyValue = sortableTable.tKeyId;
        connect( outputCell, "onclick",
                 bind( linkFunc, sortableTable, idValue, keyValue ) );
      }
    }
  },

  "writeMouseObject": function ( inputRow, sortableTable ) {
    var mouseObject = new Array();

    if ( this.cIsMouse && !isUndefinedOrNull(inputRow[this.cMouseInfo]) ) {
      var mouseInfo = inputRow[ this.cMouseInfo ];
      mouseObject[ "Over" ] = this.cMouseOverFunc;
      mouseObject[ "Out" ] = this.cMouseOutFunc;
      mouseObject[ "Info" ] = mouseInfo;
    } else {
      mouseObject[ "Over" ] = null;
      mouseObject[ "Out" ] = null;
      mouseObject[ "Info" ] = null;
    }

    return mouseObject;
  },

  /* Accepts the element and the mouse object returned from writeMlouseObject() */
  "attachMouseFunctions" : function(elem, mo, isMouse, mouseDisplayColumn)
  {
    if ( isMouse ) {
      var getEventFunc = function(str, func)
        { return function(e) { return func(e, str, mouseDisplayColumn); }; }

      if(!isUndefinedOrNull(mo.Over) && !isUndefinedOrNull(mo.Out))
      {
        var localOverFunc = null;
        if(typeof mo.Over === "string")
          { localOverFunc = window[ mo.Over ]; }
        else if(typeof mo.Over === "function")
          { localOverFunc = mo.Over; }

        if(typeof localOverFunc !== "function")
          { log("ERROR: invalid function named specified for mouseObject.Over."); }
        else
          { connect(elem, "onmouseover", getEventFunc(mo.Info, localOverFunc)); }

        var localOutFunc = null;
        if(typeof mo.Out === "string")
          { localOutFunc = window[ mo.Out ]; }
        else if(typeof mo.Out === "function")
          { localOutFunc = mo.Out; }

        if(typeof localOutFunc !== "function")
          { log("ERROR: invalid function named specified for mouseObject.Out."); }
        else
          { connect(elem, "onmouseout", getEventFunc(mo.Info, localOutFunc)); }
      }
    }
  },

  "writeDrilldownObject": function (inputRow, sortableTable, isLink) {
    if (this.drilldown) {
      var rowId = sortableTable.currentRow;
      var colId = sortableTable.currentCol;
      var outputCell = A({'class':'scs_drilldown_link scs_drilldown_expand',
                          'onclick': `${sortableTable.tName}.drilldown(${rowId}, ${colId}, ${inputRow[this.drilldownCol]})`},
                          (isLink ? '' : inputRow[this.cId]));
      // Checks for drilldown link mappings defined
      if (typeof sortableTable.drilldownLinks === 'undefined')
        sortableTable.drilldownLinks = {};
      if (typeof sortableTable.drilldownLinks[rowId] === 'undefined')
        sortableTable.drilldownLinks[rowId] = {};
      sortableTable.drilldownLinks[rowId][colId] = outputCell;
      return outputCell;
    } else return null;
  },

  "writeCell": function ( inputRow, sortableTable ) {
    var outputCell;

    var hideChar = "";
    if ( this.hideColumn != "" )
      hideChar = sortableTable.getColumnValue( inputRow[ sortableTable.tKeyId ], this.hideColumn );

    var isHiddenColumn = ( hideChar == "T" );

    var linkChar = "";
    if ( this.cLinkColumn != "" )
      linkChar = sortableTable.getColumnValue( inputRow[ sortableTable.tKeyId ], this.cLinkColumn );

    var isLink = ( ( linkChar != "F" ) && ( linkChar != "false" ) && ( this.cIsLink ) );

    // Build the mouseover and mouseout javascript function call.
    var mouseObject = this.writeMouseObject( inputRow, sortableTable );

    // Build the output object depending on the type of the row.
    if ( isHiddenColumn ) {
      outputCell = "";
    } else if ( this.cType == SORTABLE.COLUMN_TYPE_NORMAL || 
                this.cType == SORTABLE.COLUMN_TYPE_DATE ||
                this.cType == SORTABLE.COLUMN_TYPE_TIMESTAMP ||
                this.cType == SORTABLE.COLUMN_TYPE_PHONE_NUMBER ||
                this.cType == SORTABLE.COLUMN_TYPE_FILE_SIZE ||
                this.cType == SORTABLE.COLUMN_TYPE_CURRENCY ||
                this.cType == SORTABLE.COLUMN_TYPE_BOOLEAN ||
                this.cType == SORTABLE.COLUMN_TYPE_COUNTDOWN ||
                this.cType == SORTABLE.COLUMN_TYPE_SIZE ) {
      var columnValue = inputRow[ this.cId ];

      if ( typeof columnValue == "undefined" ) {
        columnValue = null;
      }

      if ( this.cType == SORTABLE.COLUMN_TYPE_DATE ) {
        columnValue = FormatHandler().formatDateString( columnValue );
      }
      else if ( this.cType == SORTABLE.COLUMN_TYPE_PHONE_NUMBER ) {
        columnValue = FormatHandler().formatPhoneNumber( columnValue );
      }
      else if ( ( this.cType == SORTABLE.COLUMN_TYPE_TIMESTAMP ) ||
                ( this.cType == SORTABLE.COLUMN_TYPE_COUNTDOWN ) ) {
        if ( !this.cTimeCdToggle ) {
          this.cCdView = ( this.cType == SORTABLE.COLUMN_TYPE_COUNTDOWN );
        }

        if ( this.cTimeCdToggle ) {
          this.cIsMouse = true;
          mouseObject[ "Info" ] = "ADID";

          if ( ( isUndefinedOrNull( mouseObject[ "Over" ] ) ) &&
               ( isUndefinedOrNull( mouseObject[ "Out" ] ) ) ) {
            var self = this;
            var lColumnValue = columnValue;
            var workingFunc = function( e, p ) {
              var deadlineCountdown = "<div class=\"datetime_overlib\">" +
                  unformattedTimestampToMediumDateTime( lColumnValue,
                                                        !self.cCdView, true ) +
                  "</div>";
              overlib2( e, deadlineCountdown, WIDTH, 275, HEIGHT, 75 );
            }

            mouseObject[ "Over" ] = workingFunc;
            mouseObject[ "Out" ] = nd;
          }
        }
        columnValue = unformattedTimestampToMediumDateTime( columnValue, this.cCdView );
      }
      else if ( this.cType == SORTABLE.COLUMN_TYPE_SIZE ) {
        if ( this.cSizeToggle ) {
          mouseObject[ "Info" ] = "ADID";

          var sizeInInches = inputRow[ "SIZEINCHES" ];
          if ( isUndefinedOrNull( sizeInInches ) ) {
            sizeInInches = "";
          }

          var aColumnValue = columnValue;
          var pColumnValue = sizeInInches;
          var mouseOverValue = this.cInchesView ? aColumnValue : pColumnValue

          if ( ( isUndefinedOrNull( mouseObject[ "Over" ] ) ) &&
               ( isUndefinedOrNull( mouseObject[ "Out" ] ) ) &&
               ( mouseOverValue != "" ) ) {
            this.cIsMouse = true;
            var self = this;

            var workingFunc = function( e, p ) {
              var sizeInfo = "<div class=\"datetime_overlib\">" +
                             mouseOverValue +
                             "</div>";
              overlib2( e, sizeInfo, WIDTH, 275, HEIGHT, 75 );
            }
            mouseObject[ "Over" ] = workingFunc;
            mouseObject[ "Out" ] = nd;
          } else {
            this.cIsMouse = false;
          }
        }
        columnValue = this.cInchesView ? pColumnValue : aColumnValue;
      }
      else if ( this.cType == SORTABLE.COLUMN_TYPE_FILE_SIZE ){
        columnValue = formatFileSize( columnValue );
      }
      else if ( this.cType == SORTABLE.COLUMN_TYPE_CURRENCY ){
        columnValue = googFormatCurrency( columnValue );
      }
      else if ( this.cType == SORTABLE.COLUMN_TYPE_BOOLEAN ){
        if ( getBoolValue( columnValue ) ) {
          columnValue = SPAN( { "class": "scs_checkmark" }, "✓" );
        } else {
          columnValue = "";
        }
      }

      var mouseChar = "";
      if ( this.cMouseColumn != "" )
        mouseChar = sortableTable.getColumnValue( inputRow[ sortableTable.tKeyId ], this.cMouseColumn );

      var isMouse = ( ( mouseChar != "F" ) && ( mouseChar != "false" ) && ( this.cIsMouse ) );

      if ( ( ! isLink ) && !this.drilldown && ( ! isMouse ) ) {
      	if ( ! this.cHTML ) {
          outputCell = !isUndefinedOrNull( columnValue ) ? columnValue : "";
        } else {
          outputCell = SPAN();
          outputCell.innerHTML = !isUndefinedOrNull( columnValue ) ? columnValue : "";
        }
      } else {
        outputCell = A( { 'class':        (isLink) ? 'pagelink' : 'mouselink',
                          'href':         "javascript:void(0)",
                          'style':        ( this.cIsMouse ) &&
                                          ( ! isLink ) ?
                                          'cursor:default;' : '' },
                                          columnValue || "" );

        this.attachMouseFunctions(outputCell, mouseObject, isMouse, this.cMouseDisplayColumn);
        this.attachLinkFunction( outputCell, inputRow, sortableTable, isLink ); 
      }

      if (this.drilldown) {
        if (isLink) {
          outputCell = SPAN(null, this.writeDrilldownObject(inputRow, sortableTable, true), outputCell);
        } else {
          outputCell = this.writeDrilldownObject(inputRow, sortableTable, false);
        }
      }
    } else if ( this.cType == SORTABLE.COLUMN_TYPE_IMAGE ) {
      var mouseChar = "";
      if ( this.cMouseColumn != "" )
        mouseChar = sortableTable.getColumnValue( inputRow[ sortableTable.tKeyId ], this.cMouseColumn );

      var isMouse = ( ( mouseChar != "F" ) && ( mouseChar != "false" ) && ( this.cIsMouse ) );

      if ( ! this.cIsStandardImage ) {
        if ( isUndefinedOrNull( this.loadEvent ) ) {
          outputCell = IMG( { 'src':    inputRow[ this.cId ] + "&timestamp=" + new Date().getTime(),
                              // FIXTHIS - What if alt text row does not exist.
                              'alt':    this.cAltText,
                              'border': '0' } );
          if ( ( ! isLink ) && ( ! isMouse ) ) {
            outputCell = outputCell;
          } else {
            outputCell = A( { 'class':        'pagelink',
                              'href':         "javascript:void(0)",
                              'style':        ( this.cIsMouse ) &&
                              ( ! isLink ) ?
                              'cursor:default;' : '' },
                            outputCell );
          }

          this.attachMouseFunctions(outputCell, mouseObject, isMouse, this.cMouseDisplayColumn);
          this.attachLinkFunction( outputCell, inputRow, sortableTable, isLink );
        }
      } else {
        var imgName = "";
        if ( ! isUndefinedOrNull( inputRow[ this.cId ] ) &&
             inputRow[ this.cId ] != "" ) {
          imgName = inputRow[ this.cId ];
        }
        else if ( this.defaultImage != "" ) {
          imgName = this.defaultImage;
        }

        var imgObj = getSpriteIMGElement( imgName );

        if ( ( ! isLink ) && ( ! isMouse ) ) {
          outputCell = imgObj;
        } else if ( imgObj ) {
          if ( isLink ) {
            var linkFunc = getGlobalFunction( this.cLinkScript );
            if ( linkFunc != null ) {
              var idValue = inputRow[ sortableTable.tKeyId ];
              var keyValue = sortableTable.tKeyId;
              connect(imgObj, "onclick", bind(linkFunc, this, idValue, keyValue));
            }
          }
          this.attachMouseFunctions(imgObj, mouseObject, isMouse, this.cMouseDisplayColumn);
          outputCell = imgObj;
	}
      }

    } else if ( ( this.cType == SORTABLE.COLUMN_TYPE_CHECKBOX ) ||
                ( this.cType == SORTABLE.COLUMN_TYPE_RADIO ) ) {

      outputCell =
          INPUT( { 'type':    this.cType == SORTABLE.COLUMN_TYPE_CHECKBOX ?
                              'checkbox' : 'radio',
                   'id':      "input_" + this.cId + "_" + inputRow[ sortableTable.tKeyId ],
                   'name':    "scs_input_" + this.cId,
                   'value':   inputRow[ sortableTable.tKeyId ] }, null );

      connect( outputCell, "onclick", this,
               function() {
                 sortableTable.toggleInputValue( this.cId, inputRow[ sortableTable.tKeyId ] );
               } );

      if ( this.cType == SORTABLE.COLUMN_TYPE_RADIO ) {
        if ( ! isUndefinedOrNull( sortableTable.tChangedRadio[ this.cId ] ) ) {
          if ( sortableTable.tChangedRadio[ this.cId ] == inputRow[ sortableTable.tKeyId ] ) {
            setNodeAttribute( outputCell, "checked", "checked" );
          }
        } else {
          if ( ( !isUndefinedOrNull( inputRow[ this.cId ] ) ) &&
               ( inputRow[ this.cId ] === true ) ) {
            setNodeAttribute( outputCell, "checked", "checked" );
            sortableTable.toggleInputValue( this.cId, inputRow[ sortableTable.tKeyId ] );
          }
        }
      } else if ( this.cType == SORTABLE.COLUMN_TYPE_CHECKBOX ) {
        var rowId = inputRow[ sortableTable.tKeyId ];
        var isPreChecked = isDefinedAndNotNull( inputRow[ this.cId ] ) &&
                           ( inputRow[ this.cId ] === true );

        if ( sortableTable.tUseMaintainChecksMethod ) {
          if ( isPreChecked ) sortableTable.markRowAsPreChecked( rowId );

          //
          // If the row is in the changed from pre-checked list, get its 
          // individual state from its selected status in the list.
          //
          var isChecked = sortableTable.statusInChangedFromPreChecked( rowId );

          //
          // If isChecked is undefined, this row is not on the "changed from
          // prechecked" list.  If there has been a "select all" or "unselect
          // all", the row's individual state is "not selected".  Since that
          // is a state change for a pre-checked row, we add that row to the
          // "changed from prechecked" list.
          //
          // If there has been no "select all" or unselect all", the row's
          // individual state is its pre-checked state.
          //
          if ( isUndefinedOrNull( isChecked ) ) {
            isChecked = isPreChecked;
            if ( sortableTable.tSelectAllStatus != "nosetall" ) {
              if ( isChecked ) {
                isChecked = false;
                sortableTable.markRowAsChangedFromPreChecked( rowId, isChecked );
              }
            }
          }

          //
          // For "select all", the effective state (which determines the
          // appearance of the checkbox) is the negation of the individual
          // state.
          //
          if ( sortableTable.tSelectAllStatus == "selectall" ) {
            isChecked = ( ! isChecked );
          }

        } else {
          var isChecked = isPreChecked || sortableTable.isItemChecked( rowId );
          var isChanged =
            ( ( ! isUndefinedOrNull( sortableTable.tChangedCheckbox[ this.cId ] ) ) &&
              ( ! isUndefinedOrNull( sortableTable.tChangedCheckbox[ this.cId ][ inputRow[ sortableTable.tKeyId ] ] ) ) );
          if ( isChanged ) isChecked = ( ! isChecked );

        }
        if ( isChecked ) setNodeAttribute( outputCell, "checked", "checked" );

      }

    } else if ( this.cType == SORTABLE.COLUMN_TYPE_TEXTFIELD || 
                this.cType == SORTABLE.COLUMN_TYPE_SELECTBOX  ) {

      var keyValue = inputRow[ sortableTable.tKeyId ];
      var valueSpan = SPAN( { 'id': sortableTable.getEditableUniqueId(keyValue, this.cId) },
                            inputRow[ this.cId ] );

      var editImg = getSpriteIMGElement("YELLOWPENCIL", getLangString("CLICKTOEDIT"));
      connect(editImg, "onclick", sortableTable.getEditFieldFunc(sortableTable.tName, keyValue, this.cId));
      outputCell = DIV(null, null);
      appendChildNodes(outputCell, valueSpan, editImg );
      setStyle(outputCell, {'margin' : '0px'});
    } else if ( this.cType == SORTABLE.COLUMN_TYPE_TOGGLE_ONE || 
                this.cType == SORTABLE.COLUMN_TYPE_TOGGLE_MULTIPLE ) {
      
      var keyValue = inputRow[ sortableTable.tKeyId ];
      outputCell = getSpriteIMGElement(this.getToggleImage(inputRow[this.cId]));
      setNodeAttribute(outputCell, "id", sortableTable.getEditableUniqueId(keyValue, this.cId));
      connect(outputCell, "onclick", sortableTable.getEditFieldFunc(sortableTable.tName, keyValue, this.cId))
    }

    if ( sortableTable.tGridView ) {
      if ( ( this.cShowTitleGridView ) &&
           ( ( this.cType != SORTABLE.COLUMN_TYPE_IMAGE ) || ( this.cIsStandardImage ) ) ) {
        var outputName = SPAN( { 'style': 'font-weight:bold;' }, this.cTitle + ": " );
      }

      var outputValue = SPAN( {}, outputCell );
      outputCell = SPAN( {}, null );

      if ( ( this.cShowTitleGridView ) &&
           ( ( this.cType != SORTABLE.COLUMN_TYPE_IMAGE ) || ( this.cIsStandardImage ) ) ) {
        appendChildNodes( outputCell, outputName );
      }

      appendChildNodes( outputCell, outputValue );
    } else {

      outputCell = TD( {}, outputCell );

      // Set alignment - needs to be done on every cell, not just header
      if( this.cType == SORTABLE.COLUMN_TYPE_CHECKBOX || 
          this.cType == SORTABLE.COLUMN_TYPE_RADIO )
        { setStyle( outputCell, { 'text-align': 'center' } ); }
      else if ( ( this.cType == SORTABLE.COLUMN_TYPE_IMAGE ) &&
                ( this.cIsStandardImage ) )
        { setStyle( outputCell, { 'text-align': 'center' } ); }
      else if( this.textAlign != "" && this.textAlign != "left" )
        { setStyle( outputCell, { 'text-align': this.textAlign } ); }

      if(this.allowWrap)
        { setStyle( outputCell, { 'white-space':'normal' }); }
    }

    // Set width - Needs to be done with an inline style to override the css rule
    if( !isUndefinedOrNull(this.colWidth) && this.colWidth > 0 )
      { setStyle( outputCell, { 'width': this.colWidth + "px" } );
        setStyle( outputCell, { 'white-space': "normal" } ); }

    // If we need to call a load event, do it now
    if ( !isUndefinedOrNull( this.loadEvent ) ) {
      // Pass the TD, The table object, the column object, and the current data row
      outputCell = this.loadEvent(outputCell, sortableTable, this, inputRow);
      this.attachMouseFunctions( outputCell, mouseObject, isMouse, this.cMouseDisplayColumn );
    }

    return outputCell;
  }
};

/*
  "SortableTable" object. This object holds all information and does all
                  manipulation of a sortable table.
 */
SortableTable = function( tableName, tableTitle, tableMessage,
                          allowListView, allowGridView, tableIsCustom,
                          allowHideable, isHidden,
                          rowsInListView, rowsInGridView,
                          tableGridView, tableShowHeader,
                          tableKeyId,
                          sortColumn, sortDirection,
                          tableCallback, tableInit, tableEdit, rowCallback,
                          toolbarActions, toolbarCallback, rowLinkFunc,
                          useChecklistMethod, useMaintainChecksMethod ) {
  bindMethods( this );
  this.initSortableTable();

  // Define the title, table name and starting message.
  this.tName = tableName;
  this.tId = ( this.tName ).substring( 3 );
  this.tTitle = tableTitle;
  this.tMessage = tableMessage;

  // Define the display information about this table.
  this.tAllowListView = allowListView;
  this.tAllowGridView = allowGridView;
  this.tRowsInListView = rowsInListView;
  this.tRowsInGridView = rowsInGridView;
  this.tGridView = tableGridView;
  this.tShowHeader = tableShowHeader;
  this.tIsCustomizable = tableIsCustom;
  this.tHideable = allowHideable;
  this.tIsHidden = allowHideable && isHidden;

  this.clearOverrides();

  // The key Id to uniquely identify a row.
  this.tKeyId = tableKeyId;

  // Other table information.
  this.tSortColumn = sortColumn;
  this.tSortDirection = sortDirection;
  this.tSortArr = [];
  this.tCallbackCommand = tableCallback;
  this.tEditCommand = tableEdit;

  // Process Callback Command
  var i = this.tCallbackCommand.indexOf( "?" );
  var spicepl = this.tCallbackCommand.substring( 0, i );
  var qstring = this.tCallbackCommand.substring( i );
  var args = parseQueryString( qstring );

  this.tWorkingObjs[ "spicepl" ] = spicepl;
  this.tWorkingObjs[ "command" ] = args.command;

  // Process Edit Command
  i = this.tEditCommand.indexOf( "?" );
  spicepl = this.tEditCommand.substring( 0, i );
  qstring = this.tEditCommand.substring( i );
  args = parseQueryString( qstring );

  this.tWorkingObjs[ "editcommand" ] = args.command;

  this.tInitCommand = tableInit;

  this.oldFilterVals = [];
  this.newFilterVals = [];

  /* Add the internal toolbarManager */
  if ( ! isUndefinedOrNull( toolbarActions ) && isNotEmpty( toolbarActions ) &&
       !isUndefinedOrNull( toolbarCallback ) && toolbarCallback != "" &&
       typeof window[toolbarCallback] == "function" ) {
    this.tbA = toolbarActions;
    this.tbC = window[toolbarCallback];
    this.tbM = new ToolbarManager( { "scrollFloat": true,
                                     "contentElement": $( this.tId + "_toolbar" ),
                                     "actionFunc": this.toolbarActionFunc } );
    forEach( toolbarActions,
             function( a ) {
               this.tbM.addAction( a );
             }, this );
  }
  
  this.tRowCallback = ( ! isUndefinedOrNull( rowCallback ) && rowCallback != "" &&
                        typeof window[rowCallback] == "function" ) ?  window[rowCallback] : noop;

  this.tRowLinkFunc = ( ! isUndefinedOrNull( rowLinkFunc ) ?
                        getGlobalFunction( rowLinkFunc ) : null );
  this.tUseChecklistMethod = ( ! isUndefinedOrNull( useChecklistMethod ) ) &&
                             useChecklistMethod;
  this.tUseMaintainChecksMethod = isDefinedAndNotNull( useMaintainChecksMethod ) &&
                                  useMaintainChecksMethod;

  // If using the method that maintains checks, don't use the checklist method.
  if ( this.tUseMaintainChecksMethod ) this.tUseChecklistMethod = false;
  this.userData = {};
};

SortableTable.prototype = {
  "initSortableTable": function () {
    // Store the columns in this table and keep a count of the columns.
    this.tColumnsGrid = new Array();
    this.tColumnsList = new Array();
    this.tColCountGrid = 0;
    this.tColCountList = 0;

    // Define the title, table name and starting message.
    this.tName = "";
    this.tId = "";
    this.tTitle = "";
    this.tMessage = "";

    // Define the display information about this table.
    this.tAllowListView = true;
    this.tAllowGridView = false;
    this.tRowsInListView = 25;
    this.tRowsInGridView = 12;
    this.tGridView = false;
    this.tShowHeader = false;
    this.tIsCustomizable = false;
    this.tHideable = false;
    this.tIsHidden = false;

    // The key Id to uniquely identify a row.
    this.tKeyId = "";

    // Define information about the page information based off the data.
    this.tData = null;
    this.tTotalPages = 0;
    this.tCurrentPage = 1;
    this.tQueryBlob = null;

    // Other table information.
    this.tSortColumn = 1;
    this.tSortDirection = true;
    this.tCallbackCommand = null;
    this.tInitCommand = null;
    this.tRefreshCommand = null;
    this.tRefreshCommandAppendFunc = null;
    this.tAutoRefresh = false;
    this.tSuspendAutoRefresh = false;
    this.tSuspendOps = false;
    this.tLastRefreshRequest = null;
    this.tLastTimeoutRequest = null;
    this.tSearchParams = null;
    this.tUseChecklistMethod = false;
    this.tUseMaintainChecksMethod = false;

    // Other variables used to maintain a sortable table.
    this.tLocked = false;
    this.tDeferred = null;
    this.tReadConfig = false;
    this.tChangedRadio = {};
    this.tChangedCheckbox = {};
    this.tWorkingObjs = {};
    this.tFilters = [];
    this.tSavedFilters = [];
    this.tSetFilter = null;

    this.tCheckedList = [];

    this.tPreCheckedList = [];
    this.tChangedFromPreChecked = [];
    this.tSelectAllStatus = "nosetall";
    
    // Toolbar Manager and callback function
    this.tbA = [];
    this.tbM = null;
    this.tbC = noop;

    this.footerIconSize = "large";
    this.showFooterSubToolbar = true;

    // Initialize all local input related variables and objects
    this.resetInputStatus();
  },

  "setSpiceCallbackCommand" : function( newSpicePath ) {
    if ( isUndefinedOrNull( this.tCallbackCommand ) || isUndefinedOrNull( this.tEditCommand ) ) {
      return false;
    }

    this.tCallbackCommand = this.tCallbackCommand.replace(/^.*spice.pl/,newSpicePath);
    this.tEditCommand = this.tEditCommand.replace(/^.*spice.pl/,newSpicePath);
    
    // Process Callback Command
    var i = this.tCallbackCommand.indexOf( "?" );
    var spicepl = this.tCallbackCommand.substring( 0, i );
    var qstring = this.tCallbackCommand.substring( i );
    var args = parseQueryString( qstring );

    this.tWorkingObjs[ "spicepl" ] = spicepl;
    this.tWorkingObjs[ "command" ] = args.command;

    // Process Edit Command
    i = this.tEditCommand.indexOf( "?" );
    spicepl = this.tEditCommand.substring( 0, i );
    qstring = this.tEditCommand.substring( i );
    args = parseQueryString( qstring );

    this.tWorkingObjs[ "editcommand" ] = args.command;
  },

  /*
    "resetTableStatus" initializes things about the table that may
     change during user interaction.  First usage is letting the user
     be able to reset the selectAll flags for the table when they 
     completely load new data
  */
  "resetInputStatus" : function()
  {
    this.tSelectAll = [];
    this.tChangedRadio = {};
    this.tChangedCheckbox = {};
    this.tCheckedList = [];
    this.setSelectAllUnchecked();
  },

  "resetMaintainedCheckStatus" : function()
  {
    this.tPreCheckedList = [];
    this.tChangedFromPreChecked = [];
    this.tSelectAllStatus = "nosetall";
    this.setSelectAllUnchecked();
  },

  "clearOverrides": function()
  { 
    // Used to determine if the admin is currently overriding 
    // their table configuration
    this.tableOverrideID = null;
    this.tableOverrideDesc = null;
  },

  "setOverrides": function(id,desc)
  {
    // Used to determine if the admin is currently overriding 
    // their table configuration
    this.tableOverrideID = id;
    this.tableOverrideDesc = desc;
  },

  "clearRefresh": function () {
    if ( ! isUndefinedOrNull( this.tLastRefreshRequest ) ) {
      this.tLastRefreshRequest.cancel();
      this.tLastRefreshRequest = null;
    }
  },

  "clearAutoRefresh": function () {
    if ( ! isUndefinedOrNull( this.tLastTimeoutRequest ) ) {
      clearTimeout( this.tLastTimeoutRequest );
      this.tLastTimeoutRequest = null;
    }
  },

  "suspendAll": function () {
    log( 'All actions suspended' );

    this.suspendOps( true );
    if ( this.tAutoRefresh )
      this.tSuspendAutoRefresh = true;
  },

  "resumeAll": function () {
    log( 'All actions resumed' );

    this.suspendOps( false );
    if ( this.tAutoRefresh ) {
      this.tSuspendAutoRefresh = false;
      this.resetAutoRefresh();
    }
  },

  "setAutoRefresh": function () {
    if ( ! this.tSuspendAutoRefresh )
      if ( isUndefinedOrNull( this.tLastTimeoutRequest ) )
        this.tLastTimeoutRequest = setTimeout( this.performAutoRefresh, SORTABLE.AUTO_REFRESH_DELAY );
  },

  "resetAutoRefresh": function () {
    if ( ! this.tSuspendAutoRefresh ) {
      this.clearAutoRefresh();
      this.setAutoRefresh();
    }
  },

  "getRefreshCommand" : function() {
    if ( ! isUndefinedOrNull( this.tRefreshCommand ) ) {
      return this.tRefreshCommand + 
                 ( !isUndefinedOrNull(this.tRefreshCommandAppendFunc) ?
                   this.tRefreshCommandAppendFunc(this) : "" );
    }
    else if ( ! isUndefinedOrNull( this.tSearchParams ) ) {
      return this.prepareXMLRequest( "refresh", null, this.tCurrentPage );
    }
    else {
     return null;
    }
  },

  "performAutoRefresh": function () {
    if ( ! this.tSuspendAutoRefresh ) {
      var url = this.getRefreshCommand();
      if ( url && isUndefinedOrNull( this.tLastRefreshRequest ) ) {
        this.tLastRefreshRequest = this.loadFromURL2( url, "", false );
        var self = this;
        this.tLastRefreshRequest.addBoth(
          function() { self.clearRefresh(); }
        );
      }
      this.resetAutoRefresh();
    }
  },

  "refreshSortableTable": function() {
    var url = this.getRefreshCommand();
 
    if ( url ) {
      this.clearRefresh();
      this.tLastRefreshRequest = this.loadFromURL2( url, "" );
      var self = this;
      this.tLastRefreshRequest.addBoth(
        function() {
          self.clearRefresh();
          if ( this.tAutoRefresh )
            self.resetAutoRefresh();
        }
      );
    }
  },

  //
  // refreshList creates a URL that calls a table's callback command to do the
  // refresh action.   The formula for the callback command typically gets the
  // table id from the request and calls PerformSortable2Navigation, which gets
  // the navigation verb "refresh" from the request.
  //
  "refreshList": function( maintainCurrentPage ) {
    var page = ( !isUndefinedOrNull( maintainCurrentPage ) &&
                 maintainCurrentPage ) ? this.tCurrentPage : null;
    var url = this.prepareXMLRequest( "refresh", null, page  );
    return this.loadFromURL2( url, "" );
  },

  "toggleAutoRefresh": function () {
    // Toggle the value of the auto refresh setting.
    this.tAutoRefresh = ( ! this.tAutoRefresh );
  },

  "toggleHiddenTable": function () {
    if ( this.tSuspendOps ) return;

    // Hide the table if it is allowed to be hidden.
    if ( this.tHideable ) {
      this.tIsHidden = ( ! this.tIsHidden );
      this.redrawSortableTable();
      this.loadSortableTable();
    }
  },

  "toggleTopHeader": function () {
    this.tShowHeader = !(this.tShowHeader === true);
  },

  "setTopHeader": function (val) {
    this.tShowHeader = val;
  },

  "loadSortableTable": function () {
    if ( ( ! this.tIsHidden ) && ( this.tInitCommand != "null" ) ) {
      var url = this.prepareXMLRequest( "init", null, null );
      this.loadFromURL2( url, "" );
      this.tInitCommand = "null";
    }
  },

  "buildSortableTable": function (now) {
    if(now === true)
    {
      this.redrawSortableTable();
      this.loadSortableTable();
    }
    else
    {
      addSCSLoadEvent( this.redrawSortableTable, false );
      addSCSLoadEvent( this.loadSortableTable, false );
    }
  },

  /*
    setRefreshCommand sets the URL that should be used to 
    auto-refresh the table to that specified by 'refreshCommand'.

    If there is additional, dynamic information that needs to 
    be included in the URL, you can specify 'appendFunc' as a 
    function that will take the table object as a parameter, 
    and it should return a string which will be appended 
    directly to the end of 'refreshCommand'.  Obvious usage
    is for including checkbox changes during an auto-refresh.
    See setRefreshCommandWithCheckboxChanges() for an example

  */ 
  "setRefreshCommand": function ( refreshCommand, appendFunc ) {
    // Set the refresh command to be used by this table.
    this.tRefreshCommand = refreshCommand;

    if(typeof appendFunc === "function")
    {
      this.tRefreshCommandAppendFunc = appendFunc;
    }
  },

  "setUseChecklistMethod": function( useChecklistMethod ) {
    this.tUseChecklistMethod = useChecklistMethod;
    this.tUseMaintainChecksMethod = false;
  },

  /**
   * Save off the search parameters used to build this table.  If set,
   * these are sent along with the navigation commands.
   */
  "setSearchParams": function( keys, values ) {
    this.tSearchParams = { "keys": keys, "values": values };

    // Search params always use the checklist method for storing selected values.
    this.tUseChecklistMethod = true;
    this.tUseMaintainChecksMethod = false;
  },

  "addSortableColumn": function ( sortableColumn ) {
    // Add a new SortableColumn object to this SortableTable object.
    this.tColumnsGrid[ this.tColCountGrid ] = sortableColumn;
    this.tColCountGrid++;

    this.tColumnsList[ this.tColCountList ] = sortableColumn;
    this.tColCountList++;
  },

  "getQueryBlob": function() {
    if ( isUndefinedOrNull( this.tQueryBlob ) ) {
      return "";
    } else {
      return this.tQueryBlob;
    }
  },

  "getVisibleColCount": function () {
    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    // Determine the number of visible columns - based off grid or list view.
    var counter = 0;
    for ( var j = 0 ; j < lColCount ; j++ ) {
      var sortableColumn = lColumns[ j ];
      if ( sortableColumn.cVisible &&
           ( ( sortableColumn.cType != SORTABLE.COLUMN_TYPE_IMAGE ) ||
             ( this.tGridView || sortableColumn.cIsListImage ) ) )
        counter++;
    }
    return counter;
  },

  /**
   * Move to the specified page of the table.
   * @param {number} The page number to move to.
   * @optparam {boolean} true if a reload, forces the goto even if page is the current page.
   * @return {object} A MochiKit.Async.Deferred object.
   */
  "goToPage": function( page, isReload ) {
    if ( isUndefinedOrNull( isReload ) ) isReload = false;

    if ( ( ! isReload ) && ( page == this.tCurrentPage ) ) {
      return succeed();
    }

    if ( page > 0 && page <= this.tTotalPages ) {
      var nav;

      // Determine the "nav" to send based of the current and desired pages.
      if ( page == this.tTotalPages ) { nav = "last"; }
      else if ( page == this.tCurrentPage + 1 ) { nav = "next"; }
      else if ( page == this.tCurrentPage - 1 ) { nav = "previous"; }
      else if ( page == 1 ) { nav = "first"; }
      else { nav = "goto"; }

      var url = this.prepareXMLRequest( nav, null, page );
      this.resetInputStatus();
      return this.loadFromURL2( url, "" );
    }
    else {
      return succeed();
    }
  },

  "reloadCurrentPage": function() {
    this.goToPage( this.tCurrentPage, true );
  },

  /**
   * Move to the first page of the table.
   * @return {object} A MochiKit.Async.Deferred object.
   */
  "firstPage": function() {
    if ( this.tCurrentPage != 1 ) {
      return this.goToPage( 1 );
    }
    else {
      return succeed();
    }
  },

  /**
   * Move to the previous page of the table.
   * @param {boolean} [cycle=false] If cycling, calling the when on the first
   *        page will go to the last page.
   * @return {object} A MochiKit.Async.Deferred object.
   */
  "previousPage": function( cycle ) {
    cycle = isUndefinedOrNull( cycle ) ? false : cycle;
    if ( cycle && this.tCurrentPage == 1 ) {
      return this.goToPage( this.tTotalPages );
    }
    else if ( this.tCurrentPage > 1 ) {
      return this.goToPage( this.tCurrentPage - 1 );
    }
    else {
      return succeed();
    }
  },

  /**
   * Move to the next page of the table.
   * @param {boolean} [cycle=false] If cycling, calling the when on the last
   *        page will go to the first page.
   * @return {object} A MochiKit.Async.Deferred object.
   */
  "nextPage": function( cycle ) {
    cycle = isUndefinedOrNull( cycle ) ? false : cycle;

    if ( cycle && this.tCurrentPage == this.tTotalPages ) {
      return this.goToPage( 1 );
    }
    else if ( this.tCurrentPage < this.tTotalPages ) {
      return this.goToPage( this.tCurrentPage + 1 );
    }
    else {
      return succeed();
    }
  },

  /**
   * Move to the last page of the table.
   * @return {object} A MochiKit.Async.Deferred object.
   */
  "lastPage": function () {
    if ( this.tCurrentPage != this.tTotalPages ) {
      return this.goToPage( this.tTotalPages );
    }
    else {
      return succeed();
    }
  },

  "toggleRows": function( rows ) {
    // Return if rows to display did not change, otherwise set the new value.
    if ( this.tGridView ) {
      if ( this.tRowsInGridView == rows ) return;
      this.tRowsInGridView = rows;
    } else {
      if ( this.tRowsInListView == rows ) return;
      this.tRowsInListView = rows;
    }
    var url = this.prepareXMLRequest( "toggle_rows", null, null );
    this.resetInputStatus();
    this.loadFromURL2( url, "" );
  },

  "toggleView": function () {
    // Check that a multiple view of the table is allowed.
    if ( ( ! this.tAllowListView ) || ( ! this.tAllowGridView ) )
      return;

    // Set the view of the table to the kind which it is not currently set.
    this.tGridView = ( ! this.tGridView );

    // Send the request to the server to get the new table view data.
    var url = this.prepareXMLRequest( "toggle_view", null, null );
    this.resetInputStatus();
    this.loadFromURL2( url, "" );
  },

  "getSortableTableElement": function () {
    // Get the DIV in which this SCS table resides.
    var tableName = "scsTable_" + this.tId;
    return document.getElementById( tableName );
  },

  "redrawSortableTable": function (readConfig, skipFilter) {
    readConfig = isUndefinedOrNull(readConfig) ? false : readConfig;
    skipFilter = isUndefinedOrNull(skipFilter) ? false : skipFilter;
    // Get the DIV in which to draw this specific SCS table.
    var tableName = "scsTable_" + this.tId;
    var tableId = document.getElementById( tableName );

    // If the config file was not read, read it only once.
    if ( readConfig || ! this.tReadConfig ) {
      this.readInitialConfig( null, null, null, skipFilter );
      this.tReadConfig = true;
    }

    // Output the sortable table.
    this.writeSortableTable( tableId );
  },
  
  "getRestorableObj": function()
  {
    var keys = [ "rpage", "rcol", "rdir", "rxml", "rfblob" ];
    var vals = [ this.tCurrentPage, this.tSortColumn, 
                 this.tSortDirection, this.writeJSONObject(), 
                 JSON.stringify( { "filterlist": this.tFilters } ) ];
    return { "keys": keys,
             "vals": vals };
  },

  "prepareXMLRequest": function ( nav, colObj, page ) {
    var params = {};
    var url = ( nav == "init" ) ? this.tInitCommand : this.tCallbackCommand;

    if ( url.slice( -1 ) != '?' ) {
      url += "&";
    }

    if ( this.tSearchParams != null ) {
      url += queryString( this.tSearchParams.keys, this.tSearchParams.values );
    }

    // Set the "nav" command if defined.
    if ( nav ) {
      params["nav"] = nav;
    }

    // Set the "col" to be sorted on if defined.
    if ( colObj ) {
      params["col"] = colObj["col"];
      params["dir"] = colObj["dir"];
      params["colname"] = colObj["name"].toLowerCase();
    }
    else if ( this.tSortArr[0] ) {
      params["col"] = this.tSortArr[0].columnNumber;
      params["dir"] = this.tSortArr[0].direction == "asc";
      params["colname"] = this.tSortArr[0].columnId.toLowerCase();
    }
    else {
      params["col"] = this.tSortColumn;
      params["dir"] = this.tSortDirection;
    }

    // Set the "page" to go to directly if defined.
    if ( page ) {
      params["page"] = page;
    }

    params["listrows"] = this.tRowsInListView;
    params["gridrows"] = this.tRowsInGridView;
    params["gridview"] = this.tGridView;
    params["showheader"] = this.tShowHeader;
    params["tableid"] = this.tId;

    if ( this.tSearchParams != null ) {
      params["lucenefilter"] = this.buildLuceneFilter();
      params["luceneSort"] = this.getLuceneSortStr();
    }
    else {
      params["filterblob"] = this.buildFilterBlob();
    }

//    params["rxml"] = this.writeJSONObject();

    if ( url.slice( -1 ) != '?' && url.slice( -1 ) != '&' ) {
      url += "&";
    }

    return ( url + queryString( params ) );
  },

  "loadFromURL2" : function(u,m,p)
  {
    return this.loadFromURL( u, m, p);
  },

  "loadFromURL": function ( url, message, progbar, extraCallback ) {
    if ( isUndefinedOrNull( progbar ) ) { progbar = true; }
    if ( isUndefinedOrNull( message ) || ( message == "" ) )
      message = getLangString( "PROCESSING" );

    if ( this.tLocked )
      log( 'loadFromURL', 'Locked' );
    else {
      log( 'loadFromURL', url );

      this.tLocked = true;
      if(progbar) { openProgressBar(); }

      var d;
      if ( this.tDeferred )
        this.tDeferred.cancel();

      var self = this;

      var urlParams = url.split('?')[1];
      url = url.split('?')[0];

      var params = parseQueryString( urlParams );
      params = merge( params,
                      { "timestamp": new Date().getTime(),
                        "listrows": this.tRowsInListView,
                        "gridrows": this.tRowsInGridView,
                        "gridview": this.tGridView } );

      if ( !isUndefinedOrNull( this.jsonObject ) ) {
        params["sortcol"] = this.jsonObject.sortcol;
        params["sortdir"] = this.jsonObject.sortdir;
      }

      var savedCommand = params.form_id;
      delete( params.form_id );

      if ( ! this.tUseMaintainChecksMethod ) {
        var lKV = this.getAllCheckboxChanges();
        params = merge( params,
                        keyValsToObj( lKV.keys, lKV.vals ) );
      }
      this.tChangedCheckbox = {};

      d = ajax( savedCommand,
                { "method": ajaxMethod.POST,
                  "commandType": ajaxCommand.FormId,
                  "spicePLPath": url,
                  "params": params,
                  "onSuccessCallbackFunc":
                    function( dataObj ) {
                      self.datatableFromJSONRequest( dataObj );
                      self.initWithData( dataObj );
                    }
                } );

      // Keep track of the current deferred, so that we can cancel it.
      this.tDeferred = d;

      // On success or error, remove the current deferred because it has
      // completed and pass through the result or error.
      d.addBoth( function ( res ) {
        if(progbar) { closeProgressBar(); }
        self.tLocked = false;
        self.tDeferred = null;
        log( 'loadFromURL success' );
        return res;
      });

      // If an additional callback function is passed add it to the deferred
      // object.
      if ( !isUndefinedOrNull( extraCallback ) ) {
      	d.addCallback( extraCallback );
      }

      // If anything goes wrong, except for a simple cancellation alert.
      d.addErrback( function ( err ) {
        if ( !( err instanceof CancelledError ) ) {
          alert( err );
        }
      });
      return d;
    }
  },

  "initWithData": function ( data ) {
    this.tData = ( typeOf( data.Table.Record ) !== "array" ) ?
                 [ data.Table.Record ] : data.Table.Record;
    this.tTotalNumberOfRows = parseInt( data.Table.TotalNumberOfRows, 10 );
    this.tTotalPages = parseInt( data.Table.TotalPages, 10 );
    this.tCurrentPage = parseInt( data.Table.CurrentPage, 10 );

    if ( isUndefinedOrNull( data.Table.QueryBlob ) ) {
      this.tQueryBlob = "";
    } else {
      this.tQueryBlob = data.Table.QueryBlob;
    }

    this.tSortColumn = parseInt(data.Table.SortColumn, 10 );
    this.tSortDirection = ( data.Table.SortDirection == 1 ) ? true : false;
    if ( !isUndefinedOrNull( data.Table.TableMessage ) ) {
      this.tMessage = data.Table.TableMessage;
    }
    if(!isUndefinedOrNull(data.Table.xmlDoc))
      { this.xmlDoc = data.Table.xmlDoc; }
    if(!isUndefinedOrNull(data.Table.jsonObj))
      { this.jsonObj = data.Table.jsonObj; }

    this.redrawSortableTable();
    this.setPriorityColors();
  },

  "setColumnValue": function ( keyValue, columnId, newValue ) {
    if ( ( this.tKeyId == null ) || ( this.tKeyId == "" ) ) {
      return false;
    }
    else {
      for (var i = 0 ; i < this.tData.length ; i++ ) {
      	if ( this.tData[ i ][ this.tKeyId ] == keyValue ) {
      	  this.tData[ i ][ columnId ] = newValue;
      	  return true;
      	}
      }
    }
  },

  "getRow": function( keyValue ) {
    if ( ( this.tKeyId == null ) || ( this.tKeyId == "" ) ) {
      return null;
    }
    var i = findValue( map( itemgetter( this.tKeyId ), this.tData ),
                       keyValue );
    return ( i == -1 ) ? null : this.tData[i];
  },

  "getColumnValue": function ( keyValue, columnId ) {
    var row = this.getRow( keyValue );
    return ( row ) ? row[columnId] : null;
  },

  "datatableFromJSONRequest": function ( dataObj ) {
    var columns = [];

    if ( dataObj.Table.TotalNumberOfRows > 0 ) {
      dataObj.columns = keys( dataObj.Table.Record[0] );

      /* Set list or grid rows if the number of rows we got back is less than
       * the total number and we're on page 1.  We do this because there is no
       * good way in formula to set the initial number of rows.
       */
      if ( dataObj.Table.CurrentPage == 1 &&
           dataObj.Table.NumberOfRows < dataObj.Table.TotalNumberOfRows ) {
        if ( this.tGridView ) {
          this.tRowsInGridView = dataObj.Table.NumberOfRows;
        }
        else {
          this.tRowsInListView = dataObj.Table.NumberOfRows;
        }
      }
    } else if ( dataObj.Table.TotalNumberOfRows == -1 ) {
      dataObj.Table.TableMessage = "Error While Searching";
    }

    if(isUndefinedOrNull(dataObj.Table.Record))
      { dataObj.Table.Record = []; }

    return dataObj;
  },

  "suspendOps": function ( enableOps ) {
    if ( enableOps == this.tSuspendOps ) return;
    
    // Elements to show or hide.
    var elems = [ this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ] ];
    var visibility = this.tSuspendOps ? "hidden" : "visible";
    this.tSuspendOps = enableOps;
    forEach( elems,
             function( e ) {
               if ( ! isUndefinedOrNull( e ) ) {
                 e.style.visibility = visibility;
               }
             } );
  },

  "readInitialConfig": function (overrideConfig, overrideID, overrideDesc, skipFilter ) {
    // Get the JSON object providing table configuration information.  Use
    // the parameter if one was passed in, otherwise use the default table config
    skipFilter = isUndefinedOrNull(skipFilter) ? false : skipFilter;

    var configLoaded = false;
    var JSONObject;
    if(isUndefinedOrNull(overrideConfig))
    {
      JSONObject = window["jO_" + this.tId];
      this.clearOverrides();
    }
    else
    {
      JSONObject = parseJSON( overrideConfig );
      this.setOverrides(overrideID, overrideDesc);
      configLoaded = true;
    }

    // Need to keep this around
    this.jsonObject = JSONObject;

    // Get the Table preferences object. These are preferences that are always
    // set for this table and can never be overridden by a user. These values
    // should eventually replace all the arguments to the MACRO call when
    // creating a new scs_tables2.
    this.tablePrefsObject = window["tO_" + this.tId]

    if ( !skipFilter ) {
      // Get the JSON object for storing saved filters.
      var FilterObject = window["fO_" + this.tId]

      if ( ! isUndefinedOrNull( FilterObject ) ) {
        this.tSavedFilters = FilterObject;
        this.loadDefaultFilter();
      }

      // Get the JSON object for storing active filters.
      var ActiveFilterObject = window["afO_" + this.tId]

      if ( ! isUndefinedOrNull( ActiveFilterObject ) ) {
        this.tFilters = ActiveFilterObject.filterlist;
      }
    }

    if ( JSONObject ) {
      // Read the values from the configuration JSON object.
      this.tRowsInListView = JSONObject.listrows;
      this.tRowsInGridView = JSONObject.gridrows;
      this.tGridView = JSONObject.gridview;
      this.tShowHeader = JSONObject.showheader;
      this.tSortColumn = ( isUndefinedOrNull(JSONObject.sortcol) || JSONObject.sortcol == "") ? 
                           this.tSortColumn : JSONObject.sortcol;
      this.tSortDirection =  JSONObject["sortdir"];

      this.tAutoRefresh = JSONObject["autorefresh"];
      if ( this.tAutoRefresh ) this.resetAutoRefresh();

      // Read the arrays from the JSON object setting visible and hidden fields.
      var gridView = this.tGridView;
      var visibleListArray = JSONObject.visible;
      var visibleGridArray = JSONObject.gridvisible;
      if ( ( isUndefinedOrNull( visibleGridArray ) ) ||
           ( visibleGridArray.length == 0 ) ) {
      	visibleGridArray = visibleListArray.slice( 0 );
      }

      this.userData = JSONObject.userData || {};

      this.tGridView = false;
      this.setColumnPrefs( visibleListArray );

      this.tGridView = true;
      this.setColumnPrefs( visibleGridArray );

      // Set the columns in this table to the values in the JSON object.
      this.tGridView = gridView;
      if ( this.tGridView ) {
        this.setColumnPrefs( visibleGridArray );
      } else {
        this.setColumnPrefs( visibleListArray );
      }

      if ( configLoaded ) {
        this.handleColumnEdit();
      }
    }

  },

  "getVisibleColumns": function( gridView ) {
    var lColumns = ( gridView ? this.tColumnsGrid : this.tColumnsList );

    var colArr = [];
    forEach( filter( itemgetter( "cVisible" ), lColumns ),
             function( c ) {
               colArr.push( { "id": c.cId,
                              "viewAsCountdown": c.cCdView,
                              "viewAsInches": c.cInchesView } );
             } );
    return colArr;
  },

  "getVisibleColumnIdList": function( gridView ) {
    return map( itemgetter( "id" ), this.getVisibleColumns( gridView ) );
  },

  "getJSONObject": function() {
    return { "listrows": this.tRowsInListView,
             "gridrows": this.tRowsInGridView,
             "gridview": this.tGridView,
             "autorefresh": this.tAutoRefresh,
             "showheader": this.tShowHeader,
             "sortcol": this.tSortColumn,
             "sortdir": this.tSortDirection,
             "visible": this.getVisibleColumns( false ),
             "gridvisible": this.getVisibleColumns( true ),
             "userData": this.getUserData() };
  },

  "getUserData": function() {
    return this.userData;
  },

  "setUserData": function( obj ) {
    this.userData = obj;
  },

  "writeJSONObject": function () {
    return JSON.stringify( this.getJSONObject() );
  },
 
  "loadColumnEdit": function( floatingDiv )
  {
    var applyToSel = $("applyTableSettingsToSel");
    if(isUndefinedOrNull(applyToSel)) { return null; }

    if ( !isUndefinedOrNull( floatingDiv ) ) {
      floatingDiv.hide();
    }

    // This function will redraw the sortable table. Changing columns views
    // does not require the table to be read from the server again.
    var self = this;
    var handleRedraw = function ( localReplyManager ) {
      self.suspendOps( false );
      self.readInitialConfig(localReplyManager.getOther("BlobVal"), localReplyManager.getOther("ID"), localReplyManager.getOther("Desc"));
      self.redrawSortableTable();
    };

    var handleError = function ( lRM ) {
      self.suspendOps( false );
      defaultErrorCallback( lRM );
    };

    // Prepare the url to save the current table configuration.
    var keys = [ "itemid",   "preftype" ];
    var vals = [ this.tId,"01" ];

    keys.push("type");
    vals.push( ( (applyToSel.value == SORTABLE.DEFAULT_USERID) || 
                 (applyToSel.value == "") ) ? 
               SORTABLE.USERPREFS_TYPE_USER : 
               SORTABLE.USERPREFS_TYPE_GROUP );

    keys.push("userorsecid");
    vals.push(applyToSel.value);

    // Attempt to load the stored preference
    genericAjaxRequest( "getuserpref", false, handleRedraw, handleError, keys, vals, menuManager.getLocCGI_Main() + '/spice.pl', null, ajaxMethod.POST );
  },

  "saveColumnEdit": function() {
    // Close the column editor and save the values back into this table.
    this.clearOverrides();
    this.processColumnEdit();

    var updateInitialConfig = false;

    // Prepare the url to save the current table configuration.
    var keys = [ "itemid",   "preftype" ];
    var vals = [ this.tId,"01" ];

    var applyToSel = $("applyTableSettingsToSel");
    if(!isUndefinedOrNull(applyToSel) && applyToSel.value != "")
    {
      keys.push("type");
      vals.push( (applyToSel.value == SORTABLE.DEFAULT_USERID) ? 
                                      SORTABLE.USERPREFS_TYPE_USER : 
                                      SORTABLE.USERPREFS_TYPE_GROUP );

      keys.push("userorsecid");
      vals.push(applyToSel.value);

      if(applyToSel.value == "")
       {  updateInitialConfig = true; }
    }
    else
    {
      updateInitialConfig = true;
      keys.push("type");
      vals.push(SORTABLE.USERPREFS_TYPE_USER);
    }

    var table = window[this.tName];
    table.jsonObject = table.getJSONObject();
    keys.push( "blobval" );
    vals.push( table.writeJSONObject() );


    // This function will redraw the sortable table. Changing columns views
    // does not require the table to be read from the server again.
    var self = this;
    var handleRedraw = function ( localReplyManager ) {
      self.suspendOps( false );
      // If we saved to our own account, lets change the initial config object in memory
      if ( updateInitialConfig ) {
        window["jO_" + self.tId] = table.jsonObject;
      }
      self.redrawSortableTable( true, true );
    };

    // Send an ajax request to attempt to save the column settings.
    genericAjaxRequest( "saveuserpref", false, handleRedraw, null, keys, vals, menuManager.getLocCGI_Main() + '/spice.pl', null, ajaxMethod.POST );
  },

  /* Simple wrapper for deletColumnEdit so we can easily connect 
     it without having to pass a parameter. */
  "deleteColumnEditAndRestore" : function()
  {
    this.resumeAll();
    return this.deleteColumnEdit(true);
  },

  "deleteColumnEdit": function ( resetUserPref ) {
    resetUserPref = isUndefinedOrNull( resetUserPref ) ? false : resetUserPref;

    if ( resetUserPref ) {
      if ( ! confirm( "This will remove any changes you have made and\n" +
                      "reset your table settings to the default.\n" +
                      "Are you sure you wish to continue?" ) ) {
        return false;
      }
    }

    // Close the column editor and delete the currently overridden user pref
    var overrideID = this.tableOverrideID;
    this.clearOverrides();
    this.processColumnEdit();

    // This function will redraw the sortable table. Changing columns views
    // does not require the table to be read from the server again.
    var self = this;
    var handleRedraw;

    if ( resetUserPref ) {
      handleRedraw = function ( replyManager ) {
        if ( ( replyManager.isReplyOK() ) && ( replyManager.getReply() == "true" ) ) {
          if ( replyManager.getOther( "Prefs" ) == "OK" ) {
            var handleRestore = function( localReplyManager ) {
              self.suspendOps( false );
              self.redrawSortableTable();
              window.location.reload();
	    };

            var keys = [ "itemid", "preftype", "type" ];
            var vals = [ "adinquiry", "01", "1" ];
            genericAjaxRequest( "resetuserpref", false, handleRestore, null, keys, vals, menuManager.getLocCGI_Main() + '/spice.pl', null, ajaxMethod.POST );
          } else {
            alert( "There are no default preferences to restore!" );
      	  }
      	}
      };
    } else {
      handleRedraw = function ( localReplyManager ) {
        self.suspendOps( false );
        self.readInitialConfig();
        self.redrawSortableTable();
      };
    }

    // Prepare the url to save the current table configuration.
    var keys;
    var vals;
    var command;

    if ( resetUserPref ) {
      keys = [ "itemid", "preftype", "type" ];
      vals = [ "adinquiry", "01", "1" ];
      command = "checkuserpref";
    } else {
      keys = [ "id" ];
      vals = [ overrideID ];
      command = "deleteuserpref";
    }

    // Send an ajax request to attempt to delete the column settings.
    genericAjaxRequest( command, false, handleRedraw, null, keys, vals, menuManager.getLocCGI_Main() + '/spice.pl', null, ajaxMethod.POST );
    return true;
  },

  "applyColumnEdit": function() {
    this.clearOverrides();
    this.processColumnEdit();
    this.resumeAll();
    this.redrawSortableTable();
    return true;
  },

  /* Simple wrapper functions to pass to connect() */
  "changeColumnEditLeft" : function() { return this.changeColumnEdit("LEFT"); },
  "changeColumnEditRight" : function() { return this.changeColumnEdit("RIGHT"); },
  "changeColumnEditUp" : function() { return this.changeColumnEdit("UP"); },
  "changeColumnEditDown" : function() { return this.changeColumnEdit("DOWN"); },
  "changeColumnEditTop" : function() { return this.changeColumnEdit("TOP"); },
  "changeColumnEditBottom" : function() { return this.changeColumnEdit("BOTTOM"); },


  "changeColumnEdit": function ( changeType ) {
    // Get the hide/show select boxes.
    selectBox_Show = this.tWorkingObjs[ "visible" ];
    selectBox_Hide = this.tWorkingObjs[ "hidden" ];

    // Return if either of the hide/show boxes do not exist.
    if ( ( ! selectBox_Hide ) || ( ! selectBox_Show ) ) return;

    if ( changeType == "LEFT" ) {
      // Make the selected column from the "show" box hidden.
      for ( var i = 0 ; i < selectBox_Show.options.length ; i++ ) {
        if ( selectBox_Show.options[ i ].selected ) {
          appendChildNodes( selectBox_Hide,
              OPTION( { 'value': selectBox_Show.options[ i ].value },
                      selectBox_Show.options[ i ].text ) );
          selectBox_Show.remove( i );
          break;
        }
      }
    } else if ( changeType == "RIGHT" ) {
      // Make the selected column from the "hide" box visible.
      for ( var i = 0 ; i < selectBox_Hide.options.length ; i++ ) {
        if ( selectBox_Hide.options[ i ].selected ) {
          appendChildNodes( selectBox_Show,
              OPTION( { 'value': selectBox_Hide.options[ i ].value },
                      selectBox_Hide.options[ i ].text ) );
          selectBox_Hide.remove( i );
          break;
        }
      }
    } else if ( changeType == "UP" ) {
      // Move the column selected in the "show" box up one column.
      for ( var i = 0 ; i < selectBox_Show.options.length ; i++ ) {
        if ( selectBox_Show.options[ i ].selected ) {
          if( i > 0 ) {
            var selOpt = selectBox_Show.options[ i ];
            var previousOpt = selectBox_Show.options[ ( i - 1 ) ];
            selectBox_Show.removeChild( selOpt );
            selectBox_Show.insertBefore( selOpt, previousOpt );
          } else {
            return this.changeColumnEdit("BOTTOM"); // Wrap around if already at the top
          }
          break;
        }
      }
    } else if ( changeType == "DOWN" ) {
      // Move the column selected in the "show" box down on column.
      var max_options = ( selectBox_Show.options.length - 1 );
      for ( var i = max_options ; i >= 0 ; i-- ) {
        if ( selectBox_Show.options[ i ].selected ) {
          if( i < max_options ) {
            var selOpt = selectBox_Show.options[ i ];
            var nextOpt = selectBox_Show.options[ ( i + 1 ) ];
            selOpt = selectBox_Show.removeChild( selOpt );
            nextOpt = selectBox_Show.replaceChild( selOpt, nextOpt );
            selectBox_Show.insertBefore( nextOpt, selOpt );
          } else {
            return this.changeColumnEdit("TOP"); // Wrap around if already at the bottom
          }
          break;
        }
      }
    } else if ( changeType == "TOP" ) {
      for( var i = 1 ; i < selectBox_Show.options.length ; i++ ) {
        if( selectBox_Show.options[ i ].selected ) {
          var selOpt = selectBox_Show.options[ i ];
          selOpt = selectBox_Show.removeChild( selOpt );
          selectBox_Show.insertBefore( selOpt, selectBox_Show.firstChild );
          break;
        }
      }
    } else if ( changeType == "BOTTOM" ) {
      var max_options = ( selectBox_Show.options.length - 1 );
      for ( var i = max_options ; i >= 0 ; i-- ) {
        if ( selectBox_Show.options[ i ].selected ) {
          var selOpt = selectBox_Show.options[ i ];
          selectBox_Show.removeChild( selOpt );
          selectBox_Show.appendChild( selOpt );
          break;
        }
      }

    }
  },

  "setColumnPrefs": function ( colArrayVisible ) {
    var columns = [],
        visibleIdList = [];

    if ( colArrayVisible.length > 0 ) {
      if ( typeof colArrayVisible[0] == "string" ) {
        // We got a list of ids passed in.  That can happen if the table
        // settings are saved in the old format, or can come from changing the
        // order of the columns.
        visibleIdList = colArrayVisible;
      }
      else {
        // New format where the list is an array of objects
        visibleIdList = map( itemgetter( "id" ), colArrayVisible );
      }
    }

    // Rearrange this tables columns to match the order of the colArrayVisible.
    // This array is the order the columns will be displayed and are visible.
    forEach( colArrayVisible,
             function( c ) {
               var isObj = ( typeof c == "object" ),
                   id = isObj ? c.id : c,
                   col = this.getSortable2ColumnById( id );
               if ( !col ) {
                 return;
               }
               col.cVisible = true;
               if ( isObj ) {
                 col.cCdView = c.viewAsCountdown;
                 col.cInchesView = c.viewAsInches;
               }
               columns.push( col );
             }, this );

    // Rearrange this tables columns to match the order of the colArrayHidden.
    // This array is the order the columns will be displayed and are hidden.

    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    forEach( lColumns,
             function( c ) {
               if ( findValue( visibleIdList, c.cId ) == -1 ) {
                 c.cVisible = false;
                 columns.push( c );
               }
             } );

    // Assign the new ordered columns into the columns of this table.
    if ( this.tGridView ) {
      this.tColumnsGrid = columns;
      this.tColCountGrid = columns.length;
    } else {
      this.tColumnsList = columns;
      this.tColCountList = columns.length;
    }
  },

  "processColumnEdit": function () {
    // This resets the autorefresh when the settings are saved and closed.
    this.tSuspendAutoRefresh = false;
    if ( this.tAutoRefresh )
      this.resetAutoRefresh();

    // Set the columns to match what is currently in the select boxes.
    var selectBox_Show = this.tWorkingObjs[ "visible" ];

    if ( selectBox_Show ) {
      this.setColumnPrefs( map( itemgetter( "value" ), selectBox_Show.options ) );
    }
  },

  "writeApplyPrefsSelect": function(id)
  {
    var applyToSel =  SELECT( { "name" : id,
                                "id" :   id }, null );

    appendChildNodes(applyToSel, OPTION( { "value": "" }, getLangString("MYACCOUNT")) );
    appendChildNodes(applyToSel, OPTION( { "value": "0000000000" }, getLangString("ALLUSERS")) );

    forEach(SORTABLE.AVAIL_GROUPS, function (grp)
      {
        appendChildNodes(applyToSel, OPTION( { "value" : grp.id }, grp.desc ) );
      });

    return applyToSel;
  },

  "handleColumnEdit": function () {
    if ( this.tSuspendOps ) return;

    this.suspendOps( true );
    this.tSuspendAutoRefresh = true;
    this.clearAutoRefresh();

    var buttons = [ { "key": "APPLY",
                      "caption": getLangString( "APPLY" ),
                      "isDefault": false,
                      "isCancel": false,
                      "func": this.applyColumnEdit } ];
    var saveFunc = null;

    if ( SessionManager().getUserName() !== "" ) {
      saveFunc = this.saveColumnEdit;
      buttons.push( { "key": "RESTORE",
                      "caption": getLangString( "RESTOREDEFAULTS" ),
                      "isDefault": false,
                      "isCancel": false,
                      "func": this.deleteColumnEditAndRestore } );
    }

    var flDiv = new FloatingDiv( null,
                                 { "title": getLangString( "TABLESETTINGS" ),
                                   "saveFunc": saveFunc,
                                   "cancelFunc": this.resumeAll,
                                   "additionalButtons": buttons,
                                   "disposeOnHide": true } );

    // Create the table to hold the column editting options.
    var edit_table = TABLE( { 'style': 'width:100%;' }, null );
    var edit_tbody = TBODY();

    // Build the first row of the edit columns table.
    var edit_row = TR( {}, null );
    var edit_cell = TD( { 'colspan': '3', 'style': 'text-align:left;' }, null );

    // If we are an admin, build and apply the administrative right div
    var applyToSel = null;

    if( !isUndefinedOrNull(SORTABLE.AVAIL_GROUPS) && 
        SORTABLE.AVAIL_GROUPS.length > 0 ) 
    {
      var current_span = this.writeOverrideSpan(true);
 
      applyToSel = this.writeApplyPrefsSelect("applyTableSettingsToSel");

      var self = this;
      var applyto_link = createOnclickOnlyLink( "APPLYTO", 
                                                "tbl_set_applyto",
                                                function() { return self.saveColumnEdit( flDiv ); } );
 
      var loadfrom_link =  createOnclickOnlyLink( "LOADFROM", 
                                                  "tbl_set_loadfrom",
                                                  function() { self.loadColumnEdit( flDiv ) } );

      var rightDiv = DIV( { "style" : "float: right; clear: none; width: 40%; text-align:right;" } ,
                          current_span, isUndefinedOrNull(current_span) ? null : BR(),
                          applyToSel, BR(),
                          applyto_link, SORTABLE.SPACER_NODE(),
                          loadfrom_link );

      appendChildNodes(edit_cell, rightDiv);
    }
    
    appendChildNodes( edit_row, edit_cell );
    appendChildNodes( edit_tbody, edit_row );

    // Build the list of visible and hidden column headers.
    var list_visible = SELECT( { 'size':  '8',
                                 'style': 'min-width:200px;' }, null );
    var list_hidden = SELECT( { 'size':   '8',
                                'style':  'min-width:200px;' }, null );
    this.tWorkingObjs[ "visible" ] = list_visible;
    this.tWorkingObjs[ "hidden" ] = list_hidden;

    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    for ( var i = 0 ; i < lColCount ; i++ ) {
      var sortableColumn = lColumns[ i ];
      var colOpt = OPTION( { 'value': sortableColumn.cId }, sortableColumn.cTitle );
      if ( ! sortableColumn.cVisible )
        appendChildNodes( list_hidden, colOpt );
      else
        appendChildNodes( list_visible, colOpt );
    }

    var getButton = function(val, func)
    {
      var btn = $j( '<button><i class="fa fa-fw ' + val + '"></i></button>' )[0];
      connect(btn, "onclick", func);
      return btn;
    }

    // Create the buttons to add, remove and re-order columns.
    var buttonLeft = getButton("fa-arrow-left", anonymize(this.changeColumnEditLeft));
    var buttonRight = getButton("fa-arrow-right", anonymize(this.changeColumnEditRight));
    var buttonUp = getButton("fa-arrow-up", anonymize(this.changeColumnEditUp));
    var buttonDown = getButton("fa-arrow-down", anonymize(this.changeColumnEditDown));
    var buttonTop = getButton("fa-step-forward fa-rotate-270", anonymize(this.changeColumnEditTop));
    var buttonBottom = getButton("fa-step-forward fa-rotate-90", anonymize(this.changeColumnEditBottom));

    var edit_refresh = INPUT( { 'type':     'checkbox' }, null);
    connect(edit_refresh, "onclick", anonymize(this.toggleAutoRefresh));
                                
    if ( this.tAutoRefresh ) {
      setNodeAttribute( edit_refresh, "checked", "checked" );
    }

    var edit_header = INPUT( { 'type':     'checkbox' }, null);
    connect(edit_header, "onclick", anonymize(this.toggleTopHeader));

    if ( this.tShowHeader ) {
      setNodeAttribute( edit_header, "checked", "checked" );
    }

    // Build the second row of the edit columns table.
    edit_row = TR( {}, null );
    var edit_cell_1 = TD( { 'style': 'text-align:right;' }, null );
    appendChildNodes( edit_cell_1,
                      document.createTextNode( getLangString( "HIDDENCOLUMNS" ) ),
                      BR(), list_hidden );

    var edit_cell_2 = TD( { 'style': 'text-align:center;' }, null );
    appendChildNodes( edit_cell_2, 
                      edit_refresh,
                      document.createTextNode( getLangString( "AUTOREFRESH" ) ),
                      BR(), 
                      edit_header, 
                      document.createTextNode( getLangString( "SHOWTOPNAV" ) ),
                      BR(), BR(),
                      buttonLeft, buttonRight, BR(),
                      buttonUp, buttonDown, BR(),
                      buttonTop, buttonBottom);

    var edit_cell_3 = TD( { 'style': 'text-align:left;' }, null );
    appendChildNodes( edit_cell_3,
                      document.createTextNode( getLangString( "VISIBLECOLUMNS" ) ),
                      BR(), list_visible );

    appendChildNodes( edit_row, edit_cell_1, edit_cell_2, edit_cell_3 );
    appendChildNodes( edit_tbody, edit_row );

    if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ] ) {
      if ( this.tWorkingObjs[ "edit_div2" ] ) {
        removeElement(this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ]);
        removeElement(this.tWorkingObjs[ "edit_div2" ] );
      }
      this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ] = null;
    }


    appendChildNodes(edit_table, edit_tbody);
    appendChildNodes( flDiv.getContentElement(), edit_table );
    flDiv.show();

    if ( !isUndefinedOrNull( this.tableOverrideDesc ) ) {
      var index = -1;
      for ( var i = 0 ; i < applyToSel.options.length ; i++ ) {
        if ( applyToSel.options[ i ].text == this.tableOverrideDesc ) {
          index = i;
        }
      }

      if ( index != -1 ) {
        applyToSel.options[ index ].selected = true;
      }
    }
  },

  "getSavedFilter": function( id ) {
    var i = objIndexOf( this.tSavedFilters, "id", id );
    return ( i != -1 ) ? this.tSavedFilters[i] : null;
  },

  "loadDefaultFilter": function() {
    var defaultId = "";
    var allUsersDefaultId = "";
    var userDefaultId = "";
    var groupDefaultId = "";
    forEach( this.tSavedFilters,
             function( f ) {
               if ( f["default"] == true ) {
                 if ( f["type"] == "1") {
                   if ( f["userorsecid"] == 0 ) {
                     allUsersDefaultId = f["id"];
                   } else {
                     userDefaultId = f["id"];
                   }
                 } else if ( f["type"] == "2" ) {
                   groupDefaultId = f["id"];
                 }
               }
             } );

    if ( userDefaultId != "" ) {
      defaultId = userDefaultId;
    } else if ( groupDefaultId != "" ) {
      defaultId = groupDefaultId;
    } else if ( allUsersDefaultId != "" ) {
      defaultId = allUsersDefaultId;
    }
    if ( defaultId != "" ) {
      var filter = this.getSavedFilter( defaultId );
      this.tSetFilter = defaultId;
      this.tFilters = parseJSON( filter.blobval )["filterlist"];
    }
  },

  "applyFilterList": function () {
    var filter_list = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ];
    if ( filter_list.value == "-" ) {
      scs_alert("MSG_TBL_SELECT_FILTER_APPLY");
      return false;
    }
    this.tSetFilter = filter_list.value;
    var filter = this.getSavedFilter( filter_list.value );
    this.tFilters = parseJSON( filter.blobval )["filterlist"];
    this.performFilterNav();
    this.closeFilterList();
    return true;
  },

  "clearFilters": function() {
    this.tFilters = [];
    if(!isUndefinedOrNull(this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ]))
      { this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ].selectedIndex = 0; }
  },

  "clearFilterList": function() {
    this.closeFilterList();
    if ( isNotEmpty( this.tFilters ) ) {
      this.clearFilters();
      this.performFilterNav();
    }

    this.tSetFilter = null;
  },

  "handleFilterClick": function( e ) {
    if ( isEmpty( this.tFilters ) ) {
      return this.manageFilters();
    }
    else {
      if ( isUndefinedOrNull( this.pm ) ) {
        this.pm = new goog.ui.PopupMenu(),
             clearItem = new goog.ui.MenuItem( getLangString( "CLEAR" ) ),
             applyItem = new goog.ui.MenuItem( "Manage" + "..." );
        clearItem.setId( "CLEAR" );
        applyItem.setId( "MANAGE" );

        this.pm.addChild( clearItem, true  );
        this.pm.addChild( applyItem, true );
        this.pm.render( document.body );
        goog.events.listen( this.pm, goog.ui.Component.EventType.ACTION,
                          function( e ) {
                            var action = e.target.getId();
                            if ( action == "CLEAR" ) {
                              this.clearFilterList();
                            }
                            else if ( action == "MANAGE" ) {
                              this.manageFilters();
                            }
                          }, false, this );

      }
      this.pm.showAtElement( $( e.target.getId() ) );
    }
  },

  "closeFilterList": function () {
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ] = null;
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TEXT ] = null;
    this.suspendOps( false );
  },

  "handleSaveFilterResponse": function( localReplyManager ) {
    var filterId = localReplyManager.getOther( "ID" );
    var prefVals = this.getUserPrefsVals(this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_GROUPS1 ]);
    var filter = { "id": localReplyManager.getOther("ID"),
                   "desc": prefVals.prefix + localReplyManager.getOther("Desc"),
                   "editable": ( localReplyManager.getOther("Editable") == "T" ),
                   "userorsecid": localReplyManager.getOther( "UserOrSecId" ),
                   "type": localReplyManager.getOther( "Type" ),
                   "default": localReplyManager.getOther( "Default" ) == "T",
                   "blobval" : localReplyManager.getOther("BlobVal") }
    if ( ! this.getSavedFilter( filterId  ) ) {
      // This is a brand new filter
      this.tSavedFilters.push( filter );
    } else {
      // Remove the old one from the array and insert the new
      var i = objIndexOf( this.tSavedFilters, "id", filterId );
      this.tSavedFilters.splice( i, 1 );
      this.tSavedFilters.push( filter );
    }

    this.closeFilterList();
  },

  "updateFilterList": function () {
    var filter_list = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ];
    var desc = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TEXT ].value;
    var prefVals = this.getUserPrefsVals( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_GROUPS1 ] );

    var params = { "itemid": this.tId,
                   "id": filter_list.value,
                   "type": prefVals.type,
                   "default": $( "default_filter" ).checked,
                   "desc": desc };
    /*
     * Only send the actual filters if they have applied one and the
     * filter selected is the one that is applied.  We don't want a user just
     * trying to rename a filter that isn't the one they applied, which would
     * overwrite the existing.
     */
    if ( isNotEmpty( this.tFilters ) &&
         ( this.tSetFilter == filter_list.value ) ) {
      params["blobval"] = JSON.stringify( { "filterlist": this.tFilters } );
    }

    if ( !isUndefinedOrNull( prefVals.userorsecid ) ) {
      params["userorsecid"] = prefVals.userorsecid;
    }

    ajax( "saveuserpref",
          { "contentType": ajaxContent.XML,
            "method": ajaxMethod.POST,
            "spicePLPath": menuManager.getLocCGI_Main() + '/spice.pl',
            "params": params,
            "onSuccessCallbackFunc": this.handleSaveFilterResponse } );
    return true;
  },

  "deleteFilterList": function () {
    var filter_list = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ];
    if ( filter_list.value != "-" ) {
      if ( !window.confirm( "Are you sure you want to delete filter: " + filter_list[ filter_list.selectedIndex ].innerHTML ) ) {
        return false;
      }
    }
    if ( filter_list.value == "-" ) {
      scs_alert( "MSG_TBL_SELECT_FILTER_DELETE" );
      return false;
    }

    var self = this;
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_VALUE ] = filter_list.value;
    var handleRedraw = function ( localReplyManager ) {
      // Delete from the local memory object of filters
      var filterId = self.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_VALUE ];
      var i = objIndexOf( self.tSavedFilters, "id", filterId );
      self.tSavedFilters.splice( i, 1 );
      if ( self.tSetFilter == filterId ) {
        self.tSetFilter = null;
        self.clearFilters();
      }
      self.redrawSortableTable();
    };

    // Prepare the url to save the current table configuration.
    var keys = [ "itemid", "id" ];
    var vals = [ this.tId, this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_VALUE ] ];

    genericAjaxRequest( "deleteuserpref", false, handleRedraw, null, keys, vals, menuManager.getLocCGI_Main() + '/spice.pl', null, ajaxMethod.POST );
    this.closeFilterList();
    return true;
  },

  "saveFilterList": function() {
    var selected = getSelectedValue( $( "selected_filter" ) );
    if ( selected != "-" ) {
      return this.updateFilterList();
    }
    else {
      return this.createFilterList();
    }
  },

  "createFilterList": function () {
    var filter_list = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TEXT ];
    if ( filter_list.value == "" ) {
      scs_alert( "MSG_TBL_INVALID_FILTER_NAME" );
      return false;
    }

    var prefVals = this.getUserPrefsVals(this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_GROUPS1 ]);
    var jsonObject = { "filterlist": this.tFilters };

    // Prepare the url to save the current table configuration.
    var keys = [ "itemid", "preftype",  
                 "type",   "desc", "default",
                 "blobval" ];
    var vals = [ this.tId, SORTABLE.USERPREFS_PREFTYPE_FILTERS, 
                 prefVals.type, filter_list.value,
                 $( "default_filter" ).checked, 
                 JSON.stringify( jsonObject ) ];
    
    if(!isUndefinedOrNull(prefVals.userorsecid)) {
      keys.push("userorsecid");
      vals.push(prefVals.userorsecid);
    }

    // MBB - Changed the errback to null, this way the admin will see any 
    // error messages.  If it called handleRedraw, it would seem that 
    // everything worked.
    genericAjaxRequest( "saveuserpref", false, this.handleSaveFilterResponse, null, 
                        keys, vals, menuManager.getLocCGI_Main() + '/spice.pl', null, ajaxMethod.POST );
    return true;
  },

  "getUserPrefsVals" : function(sel)
  /* 
    Returns an object of the form:
      { "type" : Integer,
        "userorsecid" : String,
        "prefix" : String }
  */
  {
    var vals = {};
    vals.prefix = "";

    if(!isUndefinedOrNull(sel))
    {
      // We're an admin, so see who this applies to
      var userOrGrp = sel.value;

      if(userOrGrp == "")
      { 
        // This is for us
        vals.type = SORTABLE.USERPREFS_TYPE_USER;
        vals.prefix = "";
      }
      else if(parseInt(userOrGrp,10) == parseInt(SORTABLE.DEFAULT_USERID,10)) 
      { 
        // This is for all users
        vals.type = SORTABLE.USERPREFS_TYPE_USER; 
        vals.userorsecid = SORTABLE.DEFAULT_USERID;
        vals.prefix = getLangString("ALLUSERS") + ": ";
      }
      else
      { 
        // This is for a specific group
        vals.type = SORTABLE.USERPREFS_TYPE_GROUP; 
        vals.userorsecid = userOrGrp; 
        vals.prefix = sel.options[sel.selectedIndex].text + ": ";
      }
    }
    else
    {
      // Not an admin, apply to our own account
      vals.type = SORTABLE.USERPREFS_TYPE_USER;
    }

    return vals;
  },

  "updateFilterDialog": function( flDiv ) {
    var filter = this.getSavedFilter( getSelectedValue( $( "selected_filter" ) ) );
    var editable = filter ? filter["editable"] : true;
    var applyTo = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_GROUPS1 ];
    var descField = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TEXT ];
    var updateElements = function() {
      forEach( [ descField, flDiv.getButtonElement( "ok" ),
                 flDiv.getButtonElement( "delete" ), $( "default_filter" ) ],
               function( e ) {
                 setDisabled( e, editable );
               } );
    }

    if ( filter ) {
      descField.value = filter.desc;
      if ( applyTo ) {
        if ( filter.type == "2" ) {
          setSelectedIndexByValue( applyTo, filter.userorsecid );
        } else if ( filter.type == "1" && filter.userorsecid == 0 ) {
          setSelectedIndexByValue( applyTo, filter.userorsecid );
        } else {
          setSelectedIndexByValue( applyTo, "" );
        }
        /* Strip off the prefix for editing purposes */
        var prefVals = this.getUserPrefsVals( applyTo );
        descField.value = filter.desc.replace( new RegExp( "^" + prefVals.prefix ), "" );
      }
      updateElements();
      $( "default_filter" ).checked = filter["default"];
    }
    else {
      descField.value = "";
      $( "default_filter" ).checked = false;
      updateElements();
    }
  },

  "manageFilters": function () {
    if ( this.tSuspendOps ) return;

    // Suspend any other operations.
    this.suspendOps( true );
    var flDiv = new FloatingDiv( null, 
                                 { "title": getLangString( "FILTERS" ),
                                   "disposeOnHide": true,
                                   "saveFunc": this.saveFilterList,
                                   "cancelFunc": this.closeFilterList,
                                   "additionalButtons": [
                                     { "key": "apply",
                                       "caption": getLangString( "APPLY" ),
                                       "func": this.applyFilterList },
                                     { "key": "delete",
                                       "caption": getLangString( "DELETE" ),
                                       "func": this.deleteFilterList },
                                     { "key": "clear",
                                       "caption": getLangString( "CLEARFILTERS" ),
                                       "func": this.clearFilterList }
                                   ] } );

    // Get all major DIV and SPAN objects to complete the filter window.
    var manageFilterDiv = flDiv.getContentElement();
    var createFilterInput = INPUT( { 'style': 'width:15em;' }, null );

    // Build the select box to select a working filter.
    var select1Obj = SELECT( { "id": "selected_filter" } );
    connect( select1Obj, "onchange", bind( this.updateFilterDialog, this, flDiv ) );
    appendChildNodes( select1Obj, OPTION( { 'value': '-' }, "<NEW>" ) );
    forEach( this.tSavedFilters.sort( keyComparator( "desc" ) ),
             function( f ) {
               var newOption = OPTION( { 'value': f["id"],
                                         'class' : ( f["editable"] ? 
                                                     "" : 
                                                     "scs_bg_lightgray" ) },
                                       f["desc"] );
               appendChildNodes( select1Obj, newOption );
             } );

    setSelectedIndexByValue( select1Obj, this.tSetFilter );
 
    var applyToSel1 = menuManager.getAdminLevel() == "0" ? 
                      this.writeApplyPrefsSelect(SORTABLE.WORKING_OBJ_FILTER_GROUPS1) : 
                      null;

    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BOX ] = select1Obj;
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TEXT ] = createFilterInput;
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_GROUPS1 ] = applyToSel1;

    var divOpts = { "class" : "scs_left", "style" : "padding: 10px 0px; width: 100%;"};
   
    var createDiv = DIV( divOpts, 
                         TABLE( { "class": "form_table" },
                                TBODY( null, 

                                       TR( null,
                                           TD( getLangString( "CHOOSEAFILTER" ) ),
                                           TD( null,select1Obj ) ),
                                       
                                       TR( null,
                                           TD( null, getLangString( "NAME" ) ),
                                           TD( null, createFilterInput ) ),
                                       ( ( !isUndefinedOrNull(SORTABLE.AVAIL_GROUPS ) && 
                                           SORTABLE.AVAIL_GROUPS.length > 0 ) ?
                                         TR( null,
                                             TD( null, getLangString("APPLYTO") ),
                                             TD( null, applyToSel1 ) ) :
                                         null ),
                                       TR( null,
                                           TD( null, getLangString( "DEFAULT" ) ),
                                           TD( null,
                                               INPUT( { "type": "checkbox",
                                                        "value": "true",
                                                        "id": "default_filter" } ) ) ) ) ) );
    
    // Append all nodes to the manageFilterDiv.
    appendChildNodes( manageFilterDiv, createDiv );
    flDiv.show();
    this.updateFilterDialog( flDiv );
  },

  "writeOverrideSpan": function(withDelete)
  {
    withDelete = isUndefinedOrNull(withDelete) ? false : withDelete;

    var theSpan = null;
    if(!isUndefinedOrNull(this.tableOverrideID) && !isUndefinedOrNull(this.tableOverrideDesc))
    {
      theSpan = SPAN(null, "Currently viewing: " + this.tableOverrideDesc);

      if(withDelete)
      {
        var theLink = A( { 'onclick' : `${this.tName}.deleteColumnEdit()`},
                         getLangString("DELETE") ); 
        appendChildNodes(theSpan, document.createTextNode("\u00a0\u00a0"), theLink);
      }
    }
    return theSpan;    
  },
 
  "writeSortableMessage": function ( newMessage, columnTitle ) {
    if ( isUndefinedOrNull( this.tWorkingObjs[ "edit_div3" ] ) ) return;

    if ( ! isUndefinedOrNull( newMessage ) && ( trim( newMessage ) != "" ) )
      this.tMessage = newMessage;
    else
      newMessage = null;

    if ( ( ! isUndefinedOrNull( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_MESSAGE_TEXT ] ) ) &&
         ( isChildNode( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_MESSAGE_TEXT ],
                        this.tWorkingObjs[ "edit_div3" ] ) ) ) {
      removeElementClass( this.tWorkingObjs[ "edit_div3" ], "scs_hidden" );
      this.tWorkingObjs[ "edit_div3" ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_MESSAGE_TEXT ] );
      addElementClass( this.tWorkingObjs[ "edit_div3" ], "scs_hidden" );
      delete( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_MESSAGE_TEXT ] );
    }

    if ( ! isUndefinedOrNull( newMessage ) ) {
      var textNode = document.createTextNode( getSCSMessage( this.tMessage, columnTitle ) + " @ " + dateToShortTime( new Date() ) );
      this.tWorkingObjs[ SORTABLE.WORKING_OBJ_MESSAGE_TEXT ] = textNode;
      removeElementClass( this.tWorkingObjs[ "edit_div3" ], "scs_hidden" );
      appendChildNodes( this.tWorkingObjs[ "edit_div3" ], textNode );
    }
  },

  "writeSortableTable": function ( tableId ) {
    var outputMainDiv = DIV( {}, null );

    var editDiv = DIV( { 'style': 'width:100%;clear:right;' }, null );

    this.tWorkingObjs[ "edit_div2" ] = DIV( { 'style': 'width:50%;float:left;text-align:left;' }, null );
    this.tWorkingObjs[ "edit_div3" ] = DIV( { 'class': 'scs_table_message scs_hidden' }, null );

    this.writeSortableMessage( this.tMessage, "" );

    if ( ( ( this.tIsCustomizable ) || ( this.isFilterable ) ) && ( ! this.tHideable ) ) {
      this.tWorkingObjs[ "edit_div2" ].style.width = "0%";
    }

    if ( this.tHideable ) {
      if ( this.tIsHidden )
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ] = A( { 'onclick': `${this.tName}.toggleHiddenTable()` }, getLangString( "SHOW" ) + " " + this.tTitle );
      else
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ] = A( { 'onclick': `${this.tName.toggleHiddenTable()}` }, getLangString( "HIDE" ) );

      appendChildNodes( this.tWorkingObjs[ "edit_div2" ], this.tWorkingObjs[ SORTABLE.WORKING_OBJ_HIDE_SHOW ] );
    }

    var outputHeaderDiv = null;
    var outputTHead = THEAD( {}, null );
    var outputTBody = TBODY( {}, null );
    var outputTFoot = TFOOT( {}, null );
    var outputTable = TABLE( { 'id' : this.tName,
                               'class':   'datagrid borderCollapse',
                               'width':   '100%' }, null );
    var outputFooterDiv = null;
    
    if(this.tGridView) { removeElementClass(outputTable, "borderCollapse"); }

    if ( this.tShowHeader || this.tGridView ) {
      outputHeaderDiv = this.writeSortableFooter( "header" );
    }

    if ( ! this.tIsHidden ) {
      outputTHead = this.writeSortableHeader();
      outputTBody = this.writeSortableBody();
      outputFooterDiv = this.writeSortableFooter( "footer" );

      appendChildNodes( outputTable, outputTHead, outputTFoot, outputTBody );
    }

    appendChildNodes( editDiv, this.tWorkingObjs[ "edit_div2" ],
                               this.tWorkingObjs[ "edit_div3" ] );

    appendChildNodes( outputMainDiv, editDiv, outputHeaderDiv );

    if ( ! this.tIsHidden ) {
      appendChildNodes( outputMainDiv, DIV( { 'class': 'tablecontainer' }, outputTable ) );
      appendChildNodes( outputMainDiv, outputFooterDiv );
    }

    if ( this.tWorkingObjs[ "div_child" ] )
      tableId.removeChild( this.tWorkingObjs[ "div_child" ] );

    appendChildNodes( tableId, outputMainDiv );
//    MochiKit.Sortable.create( $$( "#sT_" + this.tId + " thead tr" )[0],
//                              { "tag": "TH",
//                                "overlap": "horizontal",
//                                "constraint": "horizontal",
//                                "onChange": this.columnChange,
//                                "onUpdate": this.columnUpdate } );
    this.tWorkingObjs[ "div_child" ] = outputMainDiv;

    if ( this.tbM ) {
      this.refreshToolbar();
    }
  },
  
  /**
   * Save off the current column that is being dragged.
   */
  "columnChange": function( elem ) {
    this.currentDragId = getNodeAttribute( elem, "scs:column" );
  },
  
  /**
   * The user rearranged the columns.
   */
  "columnUpdate": function( elem ) {
    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );

    /* Find the index of the column before the drag */
    var beforeIndex = findValue( map( itemgetter( "cId" ), lColumns ),
                                 this.currentDragId );
    /* Find the index of the column after the drag */
    var afterIndex = findValue( map( function( e ) {
                                       return getNodeAttribute( e,
                                                                "scs:column" );
                                     }, elem.getElementsByTagName( "TH" ) ),
                                this.currentDragId );
    /*
     *  Pull the column out and then place it at its new location in the array
     */
//    if ( this.tGridView ) {
      var currColumn = lColumns.splice( beforeIndex, 1 )[0];
      lColumns.splice( afterIndex, 0, currColumn );
//    } else {
//      var currColumn = this.tColumnsList.splice( beforeIndex, 1 )[0];
//      this.tColumnsList.splice( afterIndex, 0, currColumn );
//    }
    this.redrawSortableTable(false);
  },

  "getIndexInPreCheckedList": function( rowId ) {
    return findValue( map( itemgetter( "rowId" ), this.tPreCheckedList ),
                      rowId );
  },

  // Return true if the given row is pre-checked.
  "isRowPreChecked": function( rowId ) {
    return ( this.getIndexInPreCheckedList( rowId ) != -1 );
  },


  // Mark the given row as pre-checked by adding it the pre-checked list.
  "markRowAsPreChecked" : function( rowId ) {
    if ( ! this.isRowPreChecked( rowId ) ) {
      this.tPreCheckedList.push( { "rowId": rowId } );
    }
  },

  "getIndexInChangedFromPreChecked": function( rowId ) {
    return findValue( map( itemgetter( "rowId" ), this.tChangedFromPreChecked ),
                      rowId );
  },

  //
  // If the given row is in tChangedFromPreChecked, return its selected 
  // state; if not, return null.
  //
  "statusInChangedFromPreChecked": function( rowId ) {
    var idx = this.getIndexInChangedFromPreChecked( rowId );
    if ( idx < 0 ) return null;
    return this.tChangedFromPreChecked[idx].selected;
  },


  //
  // If the given row is marked as changed from pre-checked, toggle its
  // selected state and return true; otherwise, return false.
  //
  "toggleRowInChangedFromPreChecked": function( rowId ) {
    var idx = this.getIndexInChangedFromPreChecked( rowId );
    if ( idx < 0 ) return false;
    
    this.tChangedFromPreChecked[idx].selected =
                                ( ! this.tChangedFromPreChecked[idx].selected );
    return true;
  },

  //
  // Mark the given row as changed from pre-checked, assigning it the given
  // selected state.
  //
  "markRowAsChangedFromPreChecked": function( rowId, selectedState ) {
    this.tChangedFromPreChecked.push( { "rowId": rowId, 
                                        "selected": selectedState } );
  },

  "getIndexOfCheckedItem": function( value ) {
    return findValue( map( itemgetter( "value" ), this.tCheckedList ),
                      value );
  },

  "isItemChecked": function( value ) {
    /*
    ** For tables such as the pickup table, tUseChecklistMethod is false.
    ** In that case, this function returns false instead of checking if
    ** the item is in tCheckedList.  The appearance of the checkbox thus
    ** does not depend whether the item is in tCheckedList.
    */
    if ( this.tUseChecklistMethod ) {
      return ( this.getIndexOfCheckedItem( value ) != -1 );
    }
    else {
      return false;
    }
  },

  "addItemToCheckedList": function( columnName, value ) {
    if ( ! this.isItemChecked( value ) ) {
      this.tCheckedList.push( { "columnName": columnName,
                                "value": value } );
    }
  },

  "removeItemFromCheckedList": function( columnName, value ) {
    var i = this.getIndexOfCheckedItem( value );
    if ( i != -1 ) {
      this.tCheckedList.splice( i, 1 );
    }
  },

  "selectAll": function ( checkObj, columnName, reset ) {
     var self = this;
     var isChecked = false;

     if ( isUndefinedOrNull( reset ) ) reset = false;

     if ( ! reset ) {
       isChecked = checkObj.checked;
       this.tSelectAll[columnName] = checkObj.checked;
       for(var i = 0; i < this.tData.length; i++) {
         var row = this.tData[i];
         row[columnName] = checkObj.checked ? "true" : "false";
         var input = $("input_" + columnName + "_" + row[this.tKeyId]);
         if(!isUndefinedOrNull(input)) {
           input.checked = checkObj.checked;
           if ( ! this.tUseMaintainChecksMethod ) {
             if ( input.checked ) {
               this.addItemToCheckedList( columnName, row[ this.tKeyId ] );
             } else {
               this.removeItemFromCheckedList( columnName, row[ this.tKeyId ] );
             }
           }
         }
       }

       if ( this.tUseMaintainChecksMethod ) {
         // 
         // For "select all" or "unselect all", the "changed from pre-checked"
         // list should contain all pre-checked rows, since their individual
         // state is being changed to "not selected".  Thus the individual
         // state of all rows, pre-checked and not pre-checked, is "not
         // "selected".  For "select all", the effective state is the negation
         // of this; i.e., "selected".
         //
         this.tChangedFromPreChecked = [];
         forEach( this.tPreCheckedList,
                  function( preCheckedObj ) {
                    this.tChangedFromPreChecked.push(
                                    { "rowId": preCheckedObj.rowId,
                                      "selected": false } );
                  },
                  this 
                );
         this.tSelectAllStatus = isChecked ? "selectall" : "unselectall";
       }
     }

     //
     // This is the callback for the genericAjaxRequest call below.  Thus
     // it is not called in cases where genericAjaxRequiest is not called.
     //
     var myCallback = function( lrm ) {
       for ( var i in self.tData ) {
         var row = self.tData[ i ];
         var rowId = row[ self.tKeyId ];
         var selectedId = row[ columnName ];

         if ( selectedId == "true" ) {
           self.addItemToCheckedList( columnName, rowId );
         }
         else {
           self.removeItemFromCheckedList( columnName, rowId );
         }
       }

       var lColumns = ( self.tGridView ? self.tColumnsGrid : self.tColumnsList );

       var sortableColumn = null;
       for ( var i = 0; i < lColumns.length; i++ ) {
         if ( lColumns[i].cId == columnName ) {
          sortableColumn = lColumns[i];
         }
       }
       if ( reset ) {
         self.tChangedCheckbox = {};
         self.tCheckedList = [];
       }
       if( self.tbM ) {
         self.updateToolbarActions();
       }
       if ( reset ) {
       	 self.refreshSortableTable();
       }
       if ( sortableColumn && sortableColumn.selectAllCallback ) {
         sortableColumn.selectAllCallback();
       }
     }

     self.tChangedCheckbox = {};

     if ( ! ( this.tUseChecklistMethod || this.tUseMaintainChecksMethod ) ) {
       genericAjaxRequest("selectall_" + columnName,false,myCallback,null,
                          ["checked"],[isChecked],null,null,null,null,true);
     } else {
       //
       // Even using the new checklist method, we need to update toolbar
       // actions.  The "select all" changed the number of selected rows,
       // which could affect the enabled state of some of the actions.
       //
       if( self.tbM ) {
         self.updateToolbarActions();
       }
     }
  },

  "refreshToolbar": function ( resetCount ) {
      if ( isUndefinedOrNull( resetCount ) ) {
      	resetCount = false;
      }

      if ( resetCount ) {
      	this.tChangedCheckbox = {};
        this.tCheckedList = [];
      }
    this.updateToolbarActions();
  },

  "resetSelectBoxes2": function() {
    if ( this.tUseMaintainChecksMethod ) {
      console.log( "resetSelectBoxes2 should not be used with UseMaintainChecksMethod" );
    }
    forEach( this.tCheckedList,
             function( checkedObj ) {
               var elem = $( "input_" + checkedObj.columnName + "_" + checkedObj.value );
               if ( ! isUndefinedOrNull( elem ) )  {
                 elem.checked = false;
               }
             }, this );

    this.tChangedCheckbox = {};
    this.tCheckedList = [];
    this.updateToolbarActions();
  },

  "sortableColumnContextMenu": function( sortableColumn, outputHeadCell,
                                         isSorted, isSortedAsc,
                                         isCdView, allowTimeCdToggle,
                                         isInchesView, allowSizeToggle ) {
    if ( isUndefinedOrNull( sortableColumn.pm ) ) {
      sortableColumn.pm = new goog.ui.PopupMenu();
      if ( sortableColumn.cSortable ) {
        var sortAscItem = new goog.ui.CheckBoxMenuItem( "Sort A to Z" ),
            sortDescItem = new goog.ui.CheckBoxMenuItem( "Sort Z to A" );

        sortAscItem.setId( "SORTASC" );
        sortDescItem.setId( "SORTDESC" );
        sortAscItem.setChecked( isSorted && isSortedAsc );
        sortDescItem.setChecked( isSorted && !isSortedAsc );
        sortableColumn.pm.addItem( sortAscItem );
        sortableColumn.pm.addItem( sortDescItem );
      }
      if ( sortableColumn.cFilterType != SORTABLE.FILTER_TYPE_NONE ) {
        var filterItem = new goog.ui.MenuItem( "Filter..." );
        filterItem.setId( "FILTER" );
        sortableColumn.pm.addItem( filterItem );
      }

      if ( ( ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_TIMESTAMP ) ||
             ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_COUNTDOWN ) ) &&
           ( allowTimeCdToggle ) ) {

        var displayAsSubMenu = new goog.ui.SubMenu( "Display Column As" );
        var datetimeItem = new goog.ui.CheckBoxMenuItem( "Date/Time" );
        var countdownItem = new goog.ui.CheckBoxMenuItem( "Countdown" );

        datetimeItem.setId( "DATETIME" );
        countdownItem.setId( "COUNTDOWN" );
        datetimeItem.setChecked( !isCdView );
        countdownItem.setChecked( isCdView );

        sortableColumn.pm.addItem( new goog.ui.MenuSeparator() );
        displayAsSubMenu.addItem( datetimeItem );
        displayAsSubMenu.addItem( countdownItem );
        sortableColumn.pm.addItem( displayAsSubMenu );
      }

      if ( ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_SIZE ) &&
           ( allowSizeToggle ) ) {

        var displayAsSubMenu = new goog.ui.SubMenu( "Display Column As" );
        var aSizeItem = new goog.ui.CheckBoxMenuItem( "Size" );
        var pSizeItem = new goog.ui.CheckBoxMenuItem( "Size (in x in)" );

        aSizeItem.setId( "ASIZE" );
        pSizeItem.setId( "PSIZE" );
        aSizeItem.setChecked( !isInchesView );
        pSizeItem.setChecked( isInchesView );

        sortableColumn.pm.addItem( new goog.ui.MenuSeparator() );
        displayAsSubMenu.addItem( aSizeItem );
        displayAsSubMenu.addItem( pSizeItem );
        sortableColumn.pm.addItem( displayAsSubMenu );
      }

      sortableColumn.colMenuCallback( this, sortableColumn );
      sortableColumn.pm.render( this.getSortableTableElement() );

      goog.events.listen( sortableColumn.pm,
                          goog.ui.Component.EventType.ACTION,
                          function( e ) {
                            switch ( e.target.getId() ) {
                              case "FILTER":
                                this.onFilterClick( sortableColumn.cId, ( sortableColumn.cFilterType != SORTABLE.FILTER_TYPE_NONE ) ).call( this, e );
                                break;

                              case "SORTASC":
                              case "SORTDESC":
                                var sortDir = e.target.getId() == "SORTASC";
                                this.onSortClick( sortableColumn.cNumber,
                                                  sortableColumn.cId,
                                                  sortableColumn.cSortable,
                                                  sortDir ).call( this, e );
                                break;
                              case "DATETIME":
                              case "COUNTDOWN":
                                if ( ( e.target.getId() === "DATETIME" ) &&
                                     ( sortableColumn.cCdView ) ) {
                                  sortableColumn.cCdView = false;
                                }
                                else if ( ( e.target.getId() === "COUNTDOWN" ) &&
                                          ( !sortableColumn.cCdView ) ) {
                                  sortableColumn.cCdView = true;
                                }

                                datetimeItem.setChecked( !sortableColumn.cCdView );
                                countdownItem.setChecked( sortableColumn.cCdView );
                                this.redrawSortableTable();
                                break;
                              case "ASIZE":
                              case "PSIZE":
                                if ( ( e.target.getId() === "ASIZE" ) &&
                                     ( sortableColumn.cInchesView ) ) {
                                  sortableColumn.cInchesView = false;
                                }
                                else if ( ( e.target.getId() === "PSIZE" ) &&
                                          ( !sortableColumn.cInchesView ) ) {
                                  sortableColumn.cInchesView = true;
                                }

                                aSizeItem.setChecked( !sortableColumn.cInchesView );
                                pSizeItem.setChecked( sortableColumn.cInchesView );
                                this.redrawSortableTable();
                                break;
                            }
                          }, false, this );
    }
    else {
      if ( sortableColumn.cSortable ) {
        sortableColumn.pm.getChild( "SORTASC" ).setChecked( isSorted && isSortedAsc );
        sortableColumn.pm.getChild( "SORTDESC" ).setChecked( isSorted && !isSortedAsc );
      }
    }
    
    sortableColumn.pm.showAtElement( outputHeadCell );
    sortableColumn.pm.setVisible( true );
    return false;
  },

  "writeSortableColumnHeader": function( sortableColumn, outputHeadRow ) {
    if ( sortableColumn.cVisible &&
         ( ( sortableColumn.cType != SORTABLE.COLUMN_TYPE_IMAGE ) ||
           ( this.tGridView || sortableColumn.cIsListImage ) ) ) {
      var outputHeadCell;
      var isSorted = ( sortableColumn.cSortable &&
                       sortableColumn.cNumber == this.tSortColumn );
      var isSortedAsc = this.tSortDirection;
      var isCdView = sortableColumn.cCdView;
      var allowTimeCdToggle = sortableColumn.cTimeCdToggle;
      var isInchesView = sortableColumn.cInchesView;
      var allowSizeToggle = sortableColumn.cSizeToggle;

      if ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_CHECKBOX ) {
        var outputTitle = document.createTextNode( sortableColumn.cTitle );
        var outputCheckbox = INPUT( { 'type': 'checkbox',
                                      'id': SORTABLE.SELECT_ALL_PREFIX + sortableColumn.cId }, null );
        connect( outputCheckbox, 'onclick', this,
                 function() {
                   this.selectAll( outputCheckbox, sortableColumn.cId );
                 } );
        outputHeadCell = TH(null, null);
        if ( this.tSelectAll[ sortableColumn.cId ] )
          outputCheckbox.checked = true;

        appendChildNodes( outputHeadCell, outputTitle, BR(), outputCheckbox );
      } else {
        outputHeadCell = TH(null, sortableColumn.cTitle);
      }


      if(!sortableColumn.wrapHeader) { addElementClass(outputHeadCell, "nowrap"); }
      if( sortableColumn.cType == SORTABLE.COLUMN_TYPE_CHECKBOX || 
          sortableColumn.cType == SORTABLE.COLUMN_TYPE_RADIO || 
          sortableColumn.cIsStandardImage ) 
      { setStyle( outputHeadCell, { 'text-align': 'center' } ); }
      else if(sortableColumn.headerAlign != "left")
      { setStyle( outputHeadCell, { 'text-align': sortableColumn.headerAlign } ); }
 
      // Set width - Needs to be done with an inline style to override the css rule
      if( !isUndefinedOrNull(sortableColumn.colWidth) &&
          sortableColumn.colWidth > 0 ) {
        setStyle( outputHeadCell, { 'width': sortableColumn.colWidth + "px" } );
      }

      var clickAvailable = false;
      if ( ( sortableColumn.cSortable ) ||
           ( sortableColumn.cFilterType != SORTABLE.FILTER_TYPE_NONE ) ) {
        outputHeadCell.onmousedown = ignoreEvent;
        outputHeadCell.onmouseover = mouseOverFunc;
        outputHeadCell.onmouseout = mouseOutFunc;
        clickAvailable = true;
      }

      if ( clickAvailable ) {
        outputHeadCell.ondblclick = this.onFilterClick( sortableColumn.cId, ( sortableColumn.cFilterType != SORTABLE.FILTER_TYPE_NONE ) );
        outputHeadCell.onclick = this.onSortClick( sortableColumn.cNumber, sortableColumn.cId, sortableColumn.cSortable );
      }

      if ( isSorted ) {
        appendChildNodes( outputHeadCell,
                          SPAN( null, ( isSortedAsc ? "\u2193" : "\u2191" ) ) );
      }

      if( !isUndefinedOrNull(this.tFilters) && isNotEmpty( this.tFilters ) &&
          this.getFilterObjIndex( sortableColumn.cId ) != -1 ) { 
        addElementClass(outputHeadCell, "filtered");
      }

      outputHeadCell.oncontextmenu = bind( this.sortableColumnContextMenu,
                                           this, sortableColumn, outputHeadCell,
                                           isSorted, isSortedAsc,
                                           isCdView, allowTimeCdToggle,
                                           isInchesView, allowSizeToggle );

      setNodeAttribute( outputHeadCell, "scs:column", sortableColumn.cId );
      setNodeAttribute( outputHeadCell, "id",
                        this.tId + "_" + sortableColumn.cId );
      appendChildNodes( outputHeadRow, outputHeadCell );
    }
  },

  "writeSortableHeader": function () {
    var outputTHead;
    var outputHeadRow = TR( {}, null );

    if ( this.tGridView ) {
      outputTHead = THEAD( {}, null );
    } else {
      var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );

      outputTHead = THEAD( { 'class': 'cellgrid' }, null );
      forEach( lColumns,
               function( c ) {
                 this.writeSortableColumnHeader( c, outputHeadRow );
               }, this );
    }
    appendChildNodes( outputTHead, outputHeadRow);
    return outputTHead;
  },

  "getFooterIcon": function(overlibMsg, imgId, onClickFunc, floatStyle)
  {
    if(isUndefinedOrNull(floatStyle)) { floatStyle = "left"; }
    var li = LI( { 'style': 'float: ' + floatStyle + '; position: relative; display: inline;' },
               A( { 'class'       : 'pagelink scs_link', 
                    'href'        : 'javascript:void(0);',
                    'onmouseover' : function( event ) { overlib2(event, overlibMsg, WIDTH, 75); },
                    'onmouseout'  :  "return nd();" },
                  LI( { 'class': 'table_nav_icon scs_sprite ' + imgId }, null ) 
                 ) 
             );
    connect(li, "onclick", function( e ) { nd(); return onClickFunc( e ); });
    return li;
  },

  "footerActionFunc": function( idPrefix, e ) {
    switch ( e.target.getId() ) {
      case idPrefix + "firstpage":
        return this.firstPage();
      case idPrefix + "prevpage":
        return this.previousPage();
      case idPrefix + "nextpage":
        return this.nextPage();
      case idPrefix + "lastpage":
        return this.lastPage();
      case idPrefix + "pagesel":
        return this.goToPage( e.target.getValue() );
      case idPrefix + "rowsel":
        return this.toggleRows( e.target.getValue() );
      case idPrefix + "toggleview":
        return this.toggleView();
      case idPrefix + "print":
        return this.generateReport();
      case idPrefix + "spreadsheet":
        return this.generateSpreadsheet();
      case idPrefix + "refresh":
        return this.refreshSortableTable();
      case idPrefix + "filters":
        return this.handleFilterClick( e );
      case idPrefix + "settings":
        return this.handleColumnEdit();
      default:
        log( "unimplemented action function for " + e.target.getId() );
      }
    
  },

  "writeSortableFooter": function ( footerType ) {
    var idPrefix = this.tId + "_" + footerType + "_",
        actionFunc = partial( this.footerActionFunc, idPrefix ),
        firstPageButton = { "piIcon": "ENDLEFTARROW",
                            "tooltip": "First Page",
                            "id":  idPrefix + "firstpage",
                            "enabled": true },
        prevPageButton = { "piIcon": "DBLLEFTARROW",
                           "tooltip": "Previous Page",
                           "id": idPrefix + "prevpage",
                           "enabled": true },
        nextPageButton = { "piIcon": "DBLRIGHTARROW",
                           "tooltip": "Next Page",
                           "id": idPrefix + "nextpage",
                           "enabled": true },
        lastPageButton = { "piIcon": "ENDRIGHTARROW",
                           "tooltip": "Last Page",
                           "id": idPrefix + "lastpage",
                           "enabled": true },
        toolbar = new ToolbarManager( { "scrollFloat": false,
                                        "actionFunc": actionFunc,
                                        "piIconSize": this.footerIconSize } );

    this[footerType + "Tb"] = toolbar;
    
    if ( ( this.tTotalPages <= 1 ) || ( this.tCurrentPage == 1 ) ) {
      firstPageButton["enabled"] = false;
      prevPageButton["enabled"] = false;
    }

    if ( ( this.tTotalPages <= 1 ) ||
         ( this.tCurrentPage == this.tTotalPages ) ) {
      nextPageButton["enabled"] = false;
      lastPageButton["enabled"] = false;
    }

    toolbar.addButton( firstPageButton );
    toolbar.addButton( prevPageButton );
    toolbar.addContent( getLangString( "PAGE" ) + ":" );
    toolbar.addSelect( idPrefix + "pagesel",
                       list( range( 1, this.tTotalPages + 1 ) ),
                       this.tCurrentPage );
    toolbar.addContent( getLangString( "OF" ) + " "  + this.tTotalPages + " (" );
    var rowsPerPage = this.tGridView ? [9, 18, 27, 36] :
      [10, 25, 50, 100, 150, 200];
    var numOfRows = this.tGridView ? this.tRowsInGridView :
      this.tRowsInListView;

    toolbar.addSelect( idPrefix + "rowsel", rowsPerPage, numOfRows );
    toolbar.addContent( getLangString( "PER" ) + " " +
                        getLangString( "L_PAGE" ) + ")" );

    toolbar.addButton( nextPageButton );
    toolbar.addButton( lastPageButton );


    if ( this.showFooterSubToolbar ) {
      var subToolbar = new ToolbarManager( { "scrollFloat": false,
                                             "actionFunc": actionFunc } );

      // Add an icon to toggle the view if multiple views are allowed.
      subToolbar.addSeparator();
      if ( this.tGridView ) {
        subToolbar.addButton( { "piIcon": "LISTVIEW",
                                "tooltip": "List View",
                                "id": idPrefix + "toggleview",
                                "enabled": ( this.tAllowListView &&
                                             this.tAllowGridView ) } );
      }
      else {
        subToolbar.addButton( { "piIcon": "GRIDVIEW",
                                "tooltip": "Grid View",
                                "id": idPrefix + "toggleview",
                                "enabled": ( this.tAllowListView &&
                                             this.tAllowGridView ) } );
      }
      subToolbar.addButton( { "piIcon": "PRINTER",
                              "tooltip": "Print",
                              "id": idPrefix + "print",
                              "enabled": ( this.tablePrefsObject.printable &&
                                           !this.tSearchParams ) } );
      subToolbar.addButton( { "piIcon": "SPREADSHEET",
                              "tooltip": "Generate CSV",
                              "id": idPrefix + "spreadsheet",
                              "enabled": this.tablePrefsObject.printable } );
      subToolbar.addButton( { "piIcon": "REFRESH",
                              "tooltip": "Refresh",
                              "id": idPrefix + "refresh",
                              "enabled": ( !isUndefinedOrNull( this.tRefreshCommand ) ||
                                           !isUndefinedOrNull( this.tSearchParams ) ) } );
      subToolbar.addButton( { "piIcon": "FUNNEL",
                              "tooltip": getLangString( "FILTERS" ),
                              "id": idPrefix + "filters",
                              "enabled": this.isFilterable(),
                              "checked": isNotEmpty( this.tFilters ) } );
      subToolbar.addButton( { "piIcon": "GRAYGEAR",
                              "tooltip": getLangString( "TABLESETTINGS" ),
                              "id": idPrefix + "settings",
                              "enabled": this.tIsCustomizable } );
      toolbar.addContent( subToolbar.toolbar.getContentElement(),
                          { "float": "right" } );
    }
    return DIV( { "class": "scs_toolbar", "style": "text-align:center" },
                toolbar.toolbar.getContentElement() );
  },

  "writeSortableBody": function () {
    var outputBody = TBODY( {}, null );
    var outputRow;
    var outputCount;
    var outputDone = false;

    if ( this.tData != null ) {
      if ( this.tGridView ) {
        outputCount = 0;
        while ( ! outputDone ) {
          var rowArray = [];
          for ( var i = 0 ; i < 3 ; i++ ) {
            if ( outputCount < this.tData.length ) {
              rowArray[ i ] = this.tData[ outputCount ];
            } else {
              rowArray[ i ] = null;
              outputDone = true;
            }
            outputCount++;
          }
          outputRow = this.writeSortableGridRow( rowArray );
          appendChildNodes( outputBody, outputRow );

          if ( outputCount >= this.tData.length ) {
            outputDone = true;
          }
        }
      } else {
        for ( var i = 0 ; i < this.tData.length ; i++ ) {
          this.currentRow = i;
          outputRow = this.writeSortableListRow( this.tData[ i ] );
          if ( this.tRowLinkFunc != null ) {
            addElementClass( outputRow, "scs_link" );
            connect( outputRow, "onclick",
                     bind( this.tRowLinkFunc, this, this.tData[i][this.tKeyId] ) );
          }
          appendChildNodes( outputBody, outputRow );
          this.tRowCallback( outputRow, this.tData[ i ], this );
        }
      }
    }
 
    return outputBody;
  },
  "drilldown": function (rowId, colId, colValue) {
    if (typeof this.drilldownActive !== 'undefined' && this.drilldownActive != null) {
      removeElement(this.drilldownActive);
    }
    if (typeof this.drilldownPos !== 'undefined' && this.drilldownPos != null) {
      var linkSrc = this.drilldownLinks[this.drilldownPos[0]][this.drilldownPos[1]];
      removeElementClass(linkSrc, "scs_drilldown_collapse");
      addElementClass(linkSrc, "scs_drilldown_expand");
    }
    if (typeof this.drilldownPos !== 'undefined' && this.drilldownPos != null && rowId == this.drilldownPos[0] && colId == this.drilldownPos[1]) {
      this.drilldownPos = null;
      this.drilldownActive = null;
      return;
    }
    var clickSrc = this.drilldownLinks[rowId][colId];
    removeElementClass(clickSrc, "scs_drilldown_expand");
    addElementClass(clickSrc, "scs_drilldown_collapse");

    var tbl = $(this.tName);
    // Add cell for nesting table
    var tblDiv = DIV({'id':'scs_drilldown_div'});
    var row = TR(null, TD({'colspan':this.getVisibleColCount()}, tblDiv));
    insertSiblingNodesAfter(tbl.children[2].children[rowId], row);

    // Execute the callback
    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );

    renderCallback = lColumns[colId].drilldownCallback;
    if (renderCallback != null) {
      eval(renderCallback+'(tblDiv, colValue);');
    }
    this.drilldownPos = [rowId, colId];
    this.drilldownActive = row;
  },

  "writeSortableGridRow": function ( inputRows ) {
    var outputRow = TR();
    var outputSPAN;

    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    for ( var k = 0 ; k < inputRows.length ; k++ ) {
      var inputRow = inputRows[ k ];
      var outputTD = TD( { 'style': 'text-align:center;' }, null );
      if ( inputRow == null ) {
        appendChildNodes( outputRow, outputTD );
      } else {
        for ( var j = 0 ; j < lColCount ; j++ ) {
          var sortableColumn = lColumns[ j ];
          if ( sortableColumn.cVisible ) {
            outputSPAN = sortableColumn.writeCell( inputRow, this );
            appendChildNodes( outputTD, outputSPAN );

            appendChildNodes( outputTD, BR() );
          }
        }
        appendChildNodes( outputRow, outputTD );
      }
    }
    return outputRow;    
  },

  "writeSortableListRow": function ( inputRow ) {
    var outputRow = TR( {"class":"scs_tables_list_row"}, null );
 
    // highlight the row if needed
    if(!isUndefinedOrNull(inputRow.color))
      { setStyle(outputRow, { 'background-color' : inputRow.color }); }

    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    for ( var j = 0 ; j < lColCount ; j++ ) {
      var sortableColumn = lColumns[ j ];
      if ( ( sortableColumn.cVisible ) &&
           ( ( sortableColumn.cType != SORTABLE.COLUMN_TYPE_IMAGE ) || ( sortableColumn.cIsListImage ) ) )
      {
        this.currentCol = j;
        outputTD = sortableColumn.writeCell( inputRow, this );
        appendChildNodes( outputRow, outputTD );
      }
    }

    this.setPriorityColors( outputRow );

    return outputRow;    
  },

  "isFilterable": function () {
    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    if ( !this.tablePrefsObject.canBeFiltered ) {
      return false;
    }

    for ( var i = 0 ; i < lColCount ; i++ ) {
      var sortableColumn = lColumns[ i ];
      if ( sortableColumn.cFilterType != SORTABLE.FILTER_TYPE_NONE )
        return true;
    }
    return false;
  },

  "performFilterNav": function() {
    var url = this.prepareXMLRequest( "filter", null, null );
    this.resetInputStatus();
    this.loadFromURL2( url, "" );
  },

  "applyFilterEdit": function() {
    this.saveFilterEdit();
    this.performFilterNav();
  },

  "getFilterObjIndex": function( columnId ) {
    return findValue( map( itemgetter( "columnId" ), this.tFilters ),
                      columnId );
  },

  "clearFilterEdit": function ( columnId ) {
    this.cancelFilterEdit();
    var i = this.getFilterObjIndex( columnId );

    if ( i != -1 ) {
      this.tFilters.splice( i, 1 );
      this.performFilterNav();
    }
  },

  "cancelFilterEdit": function () {
    this.suspendOps( false );
  },

  "saveFilterEdit": function () {
    var jsonArray = [];

    if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SINGLE_SELECT ) ||
         ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_MULTI_SELECT ) ) {
      var count = 0;

      for ( var i = 0 ; i < this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][0].options.length ; i++ ) {
        if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][0].options[ i ].selected ) {
          jsonArray[ count ] = new Array();
          jsonArray[ count ][ 0 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE_NOT ].checked ? "neq" : "eq";
          jsonArray[ count ][ 1 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][0].options[ i ].value;
          jsonArray[ count ][ 2 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][0].options[ i ].text;
          jsonArray[ count ][ 3 ] = "end";
          jsonArray[ count ][ 4 ] = "";
          if ( count > 0 )
            jsonArray[ count - 1 ][ 3 ] = (jsonArray[ count - 1 ][ 0 ] === "eq") ? "or" : "and";
          count++;
        }
      }
    } else {
      for ( var i = 0 ; i < this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ].length ; i++ ) {
        jsonArray[ i ] = new Array();

        jsonArray[ i ][ 0 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ].value;
        jsonArray[ i ][ 1 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ].value;

        if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_NUMBER ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
          jsonArray[ i ][ 2 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ][ i ].value;
        } else {
          jsonArray[ i ][ 2 ] = "";
        }
        jsonArray[ i ][ 3 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ].value;

        if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) {
          jsonArray[ i ][ 4 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ].value;
        } else if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
                    ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
          jsonArray[ i ][ 4 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ].value;
        } else {
          jsonArray[ i ][ 4 ] = "";
        }

        if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) {
          jsonArray[ i ][ 5 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ].value;
          jsonArray[ i ][ 6 ] = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ i ].value;
        } else {
          jsonArray[ i ][ 5 ] = "";
          jsonArray[ i ][ 6 ] = "";
        }
      }
    }

    var saveFilter = { 'type':    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ],
                       'columnId':  this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COLUMN ].cId,
                       'filters': jsonArray };

    var i = this.getFilterObjIndex( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COLUMN ].cId );

    if ( i == -1 ) {
      // Add it
      this.tFilters.push( saveFilter );
    }
    else {
      // Replace it
      this.tFilters[ i ] = saveFilter;
    }

    this.cancelFilterEdit();
  },

  "loadFilterEdit": function ( columnId ) {
    var i = this.getFilterObjIndex( columnId );
    return i != -1 ? this.tFilters[i] : null;
  },

  "changeComparison": function( htmlObj ) {
    var workingSelect = null;
    var workingId = 0;

    if ( isUndefinedOrNull( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER ] ) )
      return;

    if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_NUMBER ) ||
         ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
         ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
         ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
      for ( var i = 0 ; i < this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ].length; i++ ) {
        if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] == htmlObj ) {
          workingSelect = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ];
          workingId = i;
          break;
        }
        if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] == htmlObj ) {
          workingSelect = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ];
          workingId = i;
          break;
        }
      }

      if ( workingSelect != null ) {
        if ( ( workingSelect.value == "bt" ) || ( workingSelect.value == "ot" ) ) {
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ workingId ].style.visibility = "visible";
          if ( ( htmlObj.previousElementSibling != null ) && ( htmlObj.previousElementSibling.value == "2" ) ) {
            if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) {
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].style.visibility = "visible";
            }
          } else {
            if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ] != null ) &&
                 ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) ) {
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].style.visibility = "hidden";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].value = "";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].style.visibility = "hidden";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].value = "";
            }
          }
        } else if ( htmlObj.textContent == "DateTime" ) {
          if ( htmlObj.value == "2" ) {
            if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ] != null ) {
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].style.visibility = "visible";
            }
            if ( ( htmlObj.nextElementSibling.value == "bt" ) || ( htmlObj.nextElementSibling.value == "ot" ) ) {
              if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) {
                this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].style.visibility = "visible";
              }
            }
          } else {
            if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) &&
                 ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) ) {
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].style.visibility = "hidden";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].value = "";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].style.visibility = "hidden";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].value = "";
            }
          }
        } else {
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ workingId ].style.visibility = "hidden";
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ workingId ].children[ 0 ].value = "";
          if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) &&
                 ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ] != null ) ) {
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].style.visibility = "hidden";
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ workingId ].value = "";
            if ( ( htmlObj.previousElementSibling != null ) && ( htmlObj.previousElementSibling.value == "1" ) ) {
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].style.visibility = "hidden";
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ workingId ].value = "";
            }
          }
        }
      }
    }
  },

  "changeCondition": function ( e ) {
    var htmlObj = e.src();
    var workingSelect = null;
    var workingId = 0;

    if ( isUndefinedOrNull( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER ] ) )
      return;

    for ( var i = 0 ; i < this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ].length; i++ ) {
      if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] == htmlObj ) {
        workingSelect = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ];
        workingId = i;
        break;
      }
    }

    if ( workingSelect != null ) {
      if ( workingSelect.value == "end" ) {
        for ( var i = ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ].length - 1 ) ; i > workingId ; i-- ) {
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ] );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ][ i ] );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ][ i ] );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ][ i ] );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] );

          if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_NUMBER ) ||
               ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
               ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
               ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
              
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ i ] );
            if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] != null ) {
              this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] );
            }

            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ].splice( i, 1 );
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ].splice( i, 1 );
          }

          if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
               ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
               ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] );
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ].removeChild( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ][ i ] );
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ].splice( i, 1 );
            this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ].splice( i, 1 );
	        }

          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ].splice( i, 1 );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ].splice( i, 1 );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ].splice( i, 1 );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ].splice( i, 1 );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ].splice( i, 1 );
          this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ].splice( i, 1 );
        }
      } else if ( ( ( workingSelect.value == "and" ) ||
                    ( workingSelect.value == "or" ) ) &&
                  ( workingId == ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ].length - 1 ) ) ) {
        var select0Obj = this.writeSelect0Filter( null, 0, this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] );
        var select1Obj = this.writeSelect1Filter( null, 0, this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] );
        var text1Obj = this.writeText1Obj( null, 0, this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] );
        var select2Obj = this.writeSelect2Filter( null, 0 );
        var i = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ].length;
        var SPObj0 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj1 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj2 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var BRObj = BR();

        var text2Obj = null;
        var text3Obj = null;
        var text4Obj = null;
        var spanObj = null;
        if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_NUMBER ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
          text2Obj = this.writeText2Obj( null, 0, this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] );
          text3Obj = null;
          text4Obj = null;
          if ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) {
            text3Obj = this.writeGenericTimeObj();
            text4Obj = this.writeGenericTimeObj();
          }
          spanObj = SPAN( {}, null );
        }

        var spanDiv = this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ];

        // Build all options into the SPAN.
        appendChildNodes( spanDiv, select0Obj, SPObj0, select1Obj, SPObj1, text1Obj, text3Obj );

        if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_NUMBER ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
          appendChildNodes( spanObj, document.createTextNode( "\u00a0" + getLangString( "AND" )  + "\u00a0" ) );
          appendChildNodes( spanObj, text2Obj, text4Obj );
          appendChildNodes( spanDiv, spanObj );
        }

        appendChildNodes( spanDiv, SPObj2, select2Obj, BRObj );

        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] = select0Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] = select1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ] = text1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ][ i ] = text2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] = select2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ i ] = spanObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ][ i ] = BRObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ][ i ] = SPObj0;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ][ i ] = SPObj1;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ][ i ] = SPObj2;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] = text3Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ i ] = text4Obj;

        if ( ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_NUMBER ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_SIZE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_DATE ) ||
             ( this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
          this.changeComparison( select1Obj );
        }
      }
    }
  },

  "writeSelect2Filter": function ( JSONObject, i ) {
    var select2Obj = SELECT();
    connect( select2Obj, 'onchange', this.changeCondition );
    appendChildNodes( select2Obj,
                      OPTION( { 'value': 'end' }, "-" ),
                      OPTION( { 'value': 'and' }, "and" ),
                      OPTION( { 'value': 'or' }, "or" ) );

    if ( JSONObject != null ) {
      for ( var j = 0 ; j < select2Obj.options.length ; j++ ) {
        if ( select2Obj.options[ j ].value == JSONObject[ "filters" ][ i ][ 3 ] ) {
          select2Obj.options[ j ].selected = true;
          break;
        }
      }
    }

    return select2Obj;
  },

  "writeSelect0Filter": function ( JSONObject, i, filterType ) {
    var select0Obj = null;

    if ( filterType == SORTABLE.FILTER_TYPE_SIZE ) {
      var select0Obj = SELECT( {}, null );
      appendChildNodes( select0Obj,
                        OPTION( { 'value': '1' }, "Width" ),
                        OPTION( { 'value': '2' }, "Height" ) );
    } else if ( filterType == SORTABLE.FILTER_TYPE_TIMESTAMP ) {
      var select0Obj = SELECT( { 'onchange': this.tName + ".changeComparison( this );" }, null );
      appendChildNodes( select0Obj,
                        OPTION( { 'value': '1' }, "Date" ),
                        OPTION( { 'value': '2' }, "Time" ) );
    } else if ( filterType == SORTABLE.FILTER_TYPE_DATE ) {
      var select0Obj = SELECT( {}, null );
      appendChildNodes( select0Obj,
                        OPTION( { 'value': '1' }, "Date" ) );
    }

      if ( ( JSONObject != null ) && ( select0Obj != null ) ) {
        for ( var j = 0 ; j < select0Obj.options.length ; j++ ) {
          if ( select0Obj.options[ j ].value == JSONObject[ "filters" ][ i ][ 4 ] ) {
            select0Obj.options[ j ].selected = true;
            break;
          }
        }
      }

    return select0Obj;
  },

  "writeSelect1Filter": function ( JSONObject, i, filterType ) {
    if ( ( filterType == SORTABLE.FILTER_TYPE_NUMBER ) ||
         ( filterType == SORTABLE.FILTER_TYPE_SIZE ) ) {
      var select1Obj = SELECT( { 'onchange': this.tName + ".changeComparison( this );" }, null );
      appendChildNodes( select1Obj,
                        OPTION( { 'value': 'eq' }, "is equal to" ),
                        OPTION( { 'value': 'lt' }, "is less than" ),
                        OPTION( { 'value': 'le' }, "is less than or equal to" ),
                        OPTION( { 'value': 'gt' }, "is greater than" ),
                        OPTION( { 'value': 'ge' }, "is greater than or equal to" ),
                        OPTION( { 'value': 'bt' }, "is between" ),
                        OPTION( { 'value': 'neq' }, "is not" ),
                        OPTION( { 'value': 'ot' }, "is outside" ) );
    } else if ( filterType == SORTABLE.FILTER_TYPE_STRING ) {
      var select1Obj = SELECT( {}, null );
      appendChildNodes( select1Obj,
                        OPTION( { 'value': 'co' }, "contains" ),
                        OPTION( { 'value': 'nco' }, "does not contain" ) );
    } else if ( ( filterType == SORTABLE.FILTER_TYPE_DATE ) ||
                ( filterType == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
      var select1Obj = SELECT( { 'onchange': this.tName + ".changeComparison( this );" }, null );
      appendChildNodes( select1Obj,
                        OPTION( { 'value': 'is' }, "is" ),
                        OPTION( { 'value': 'lt' }, "is before" ),
                        OPTION( { 'value': 'gt' }, "is after" ),
                        OPTION( { 'value': 'bt' }, "is between" ),
                        OPTION( { 'value': 'nd' }, "is not" ),
                        OPTION( { 'value': 'ot' }, "is outside" ) );
    }

    if ( JSONObject != null ) {
      for ( var j = 0 ; j < select1Obj.options.length ; j++ ) {
        if ( select1Obj.options[ j ].value == JSONObject[ "filters" ][ i ][ 0 ] ) {
          select1Obj.options[ j ].selected = true;
          break;
        }
      }
    }

    return select1Obj;
  },

  "writeGenericTimeObj": function ( JSONObject, i, j ) {
    var genericTimeVar = INPUT( { 'type': 'time', 'style': 'width:10em;' }, null );
    genericTimeVar.value = ( JSONObject != null ) ? JSONObject[ "filters" ][ i ][ j ] : "";
    return genericTimeVar;
  },

  "writeText1Obj": function ( JSONObject, i, filterType ) {
    if ( ( filterType == SORTABLE.FILTER_TYPE_NUMBER ) ||
         ( filterType == SORTABLE.FILTER_TYPE_SIZE ) ) {
      var text1Obj = INPUT( { 'type': 'text',
                              'style': 'width:5em;' }, null );
    } else if ( filterType == SORTABLE.FILTER_TYPE_STRING ) {
      var text1Obj = INPUT( { 'type': 'text',
                              'style': 'width:13em;' }, null );
    } else if ( ( filterType == SORTABLE.FILTER_TYPE_DATE ) ||
                ( filterType == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
      var text1Obj = INPUT( { 'type': 'date',
                              'style': 'width:10em;' }, null );
    }
    text1Obj.value = ( JSONObject != null ) ? JSONObject[ "filters" ][ i ][ 1 ] : "";
    return text1Obj;
  },

  "writeText2Obj": function ( JSONObject, i, filterType ) {
    if ( ( filterType == SORTABLE.FILTER_TYPE_DATE ) ||
         ( filterType == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
      var text2Obj = INPUT( { 'type': 'date',
                              'style': 'width:10em;' }, null );
    } else {
      var text2Obj = INPUT( { 'type': 'text',
                            'style': 'width:5em;' }, null );
    }
    text2Obj.value = ( JSONObject != null ) ? JSONObject[ "filters" ][ i ][ 2 ] : "";
    return text2Obj;
  },

  "writeFilter": function ( sortableColumn, xPos, yPos ) {
    var self = this;

    // Clear and close any existing filter editting windows.
    this.cancelFilterEdit();

    if ( this.tSuspendOps ) return;

    // Suspend any other operations.
    this.suspendOps( true );

    // Get all major DIV and SPAN objects to complete the filter window.
    var mainDiv = this.getSortableTableElement().parentNode;

    // Determine the placement of the div, dont wrap off the right hand side of the screen...
    var divWidth = 500;

    if( xPos + divWidth > getWindowWidth() + getScrollX() )
      { xPos = getWindowWidth() + getScrollX() - divWidth - 50; }

    var filterDiv = DIV();
    var filterSpan = SPAN( {}, null );

    // Build items for storing objects for managing filters.
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER ] = filterDiv;
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_TYPE ] = sortableColumn.cFilterType;
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COLUMN ] = sortableColumn;

    // Build all the arrays for storing objects for managing filters.
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ] = new Array();
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ] = new Array();

    // FIXTHIS - Use constants here.
    // Load any saved filter information currently loaded.
    var JSONObject = this.loadFilterEdit( sortableColumn.cId );
    var JSONArrayLen = ( JSONObject == null ) ? 1 : JSONObject[ "filters" ].length;

    if ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_DATE ) {
      // Iterate through all possible filters for this column.
      for ( var i = 0 ; i < JSONArrayLen; i++ ) {

        // Build the select object pull-down to select equality type.
        var select0Obj = this.writeSelect0Filter( JSONObject, i, sortableColumn.cFilterType );
        var select1Obj = this.writeSelect1Filter( JSONObject, i, sortableColumn.cFilterType );

        // Build the text input objects for filter 1 and 2 values.
        var text1Obj = this.writeText1Obj( JSONObject, i, sortableColumn.cFilterType );
        var text2Obj = this.writeText2Obj( JSONObject, i, sortableColumn.cFilterType );

        // Build the select to handle and/or conditions.
        var select2Obj = this.writeSelect2Filter( JSONObject, i );

        // Build two spacing objects and BR, save them off for latter removal.
        var SPObj0 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj1 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj2 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var BRObj = BR();

        // Build the SPAN that will be hidden and shown depending on comparison.
        var spanObj = SPAN( {}, null );
        appendChildNodes( spanObj,
                          document.createTextNode( "\u00a0" + getLangString( "AND" )  + "\u00a0" ),
                          text2Obj, text4Obj );

        // Attach all options to the filtering SPAN.
        appendChildNodes( filterSpan, select0Obj, SPObj0, select1Obj, SPObj1, text1Obj, text3Obj,
                          spanObj, SPObj2, select2Obj, BRObj );

        // Save off all objects for latter removal.
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] = select0Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] = select1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ] = text1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ][ i ] = text2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] = select2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ i ] = spanObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ][ i ] = BRObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ][ i ] = SPObj0;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ][ i ] = SPObj1;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ][ i ] = SPObj2;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] = null;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ i ] = null;

        // Hide or show fields based off currently selected comparison.
        this.changeComparison( select1Obj );
      }
    } else if ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_TIMESTAMP ) {
      // Iterate through all possible filters for this column.
      for ( var i = 0 ; i < JSONArrayLen; i++ ) {

        // Build the select object pull-down to select equality type.
        var select0Obj = this.writeSelect0Filter( JSONObject, i, sortableColumn.cFilterType );
        var select1Obj = this.writeSelect1Filter( JSONObject, i, sortableColumn.cFilterType );

        // Build the text input objects for filter 1 and 2 values.
        var text1Obj = this.writeText1Obj( JSONObject, i, sortableColumn.cFilterType );
        var text2Obj = this.writeText2Obj( JSONObject, i, sortableColumn.cFilterType );
        var text3Obj = this.writeGenericTimeObj( JSONObject, i, 5 );
        var text4Obj = this.writeGenericTimeObj( JSONObject, i, 6 );

        // Build the select to handle and/or conditions.
        var select2Obj = this.writeSelect2Filter( JSONObject, i );

        // Build two spacing objects and BR, save them off for latter removal.
        var SPObj0 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj1 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj2 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var BRObj = BR();

        // Build the SPAN that will be hidden and shown depending on comparison.
        var spanObj = SPAN( {}, null );
        appendChildNodes( spanObj,
                          document.createTextNode( "\u00a0" + getLangString( "AND" )  + "\u00a0" ),
                          text2Obj, text4Obj );

        // Attach all options to the filtering SPAN.
        appendChildNodes( filterSpan, select0Obj, SPObj0, select1Obj, SPObj1, text1Obj, text3Obj,
                          spanObj, SPObj2, select2Obj, BRObj );

        // Save off all objects for latter removal.
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] = select0Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] = select1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ] = text1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ][ i ] = text2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] = select2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ i ] = spanObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ][ i ] = BRObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ][ i ] = SPObj0;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ][ i ] = SPObj1;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ][ i ] = SPObj2;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] = text3Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ i ] = text4Obj;

        // Hide or show fields based off currently selected comparison.
        this.changeComparison( select1Obj );
      }
    } else if ( ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_NUMBER ) ||
                ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_SIZE ) ) {
      // Iterate through all possible filters for this column.
      for ( var i = 0 ; i < JSONArrayLen; i++ ) {

        // Build the select object pull-down to select equality type.
        var select0Obj = this.writeSelect0Filter( JSONObject, i, sortableColumn.cFilterType );
        var select1Obj = this.writeSelect1Filter( JSONObject, i, sortableColumn.cFilterType );

        // Build the text input objects for filter 1 and 2 values.
        var text1Obj = this.writeText1Obj( JSONObject, i, sortableColumn.cFilterType );
        var text2Obj = this.writeText2Obj( JSONObject, i, sortableColumn.cFilterType );

        // Build the select to handle and/or conditions.
        var select2Obj = this.writeSelect2Filter( JSONObject, i );

        // Build two spacing objects and BR, save them off for latter removal.
        var SPObj0 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj1 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj2 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var BRObj = BR();

        // Build the SPAN that will be hidden and shown depending on comparison.
        var spanObj = SPAN( {}, null );
        appendChildNodes( spanObj,
                          document.createTextNode( "\u00a0" + getLangString( "AND" )  + "\u00a0" ),
                          text2Obj );

        // Attach all options to the filtering SPAN.
        appendChildNodes( filterSpan, select0Obj, SPObj0, select1Obj, SPObj1, text1Obj, spanObj,
                          SPObj2, select2Obj, BRObj );

        // Save off all objects for latter removal.
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] = select0Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] = select1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ] = text1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ][ i ] = text2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] = select2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ i ] = spanObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ][ i ] = BRObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ][ i ] = SPObj0;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ][ i ] = SPObj1;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ][ i ] = SPObj2;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] = null;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ i ] = null;

        // Hide or show fields based off currently selected comparison.
        this.changeComparison( select1Obj );
      }
    } else if ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_STRING ) {
      // Iterate through all possible filters for this column.
      for ( var i = 0 ; i < JSONArrayLen; i++ ) {

        // Build the select object pull-down to select equality type.
        var select0Obj = null;
        var select1Obj = this.writeSelect1Filter( JSONObject, i, sortableColumn.cFilterType );

        // Build the text input objects for filter 1 and 2 values.
        var text1Obj = this.writeText1Obj( JSONObject, i, sortableColumn.cFilterType );
        var text2Obj = null;

        // Build the select to handle and/or conditions.
        var select2Obj = this.writeSelect2Filter( JSONObject, i );

        // Build two spacing objects and BR, save them off for latter removal.
        var SPObj0 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj1 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var SPObj2 = document.createTextNode( "\u00a0\u00a0\u00a0" );
        var BRObj = BR();

        // Build the SPAN that will be hidden and shown depending on comparison.
        var spanObj = null;

        // Attach all options to the filtering SPAN.
        appendChildNodes( filterSpan, select1Obj, SPObj1, text1Obj,
                          SPObj2, select2Obj, BRObj );

        // Save off all objects for latter removal.
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SIZE_CHOICE ][ i ] = select0Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ i ] = select1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_1 ][ i ] = text1Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_2 ][ i ] = text2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_CONDITION ][ i ] = select2Obj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_2 ][ i ] = spanObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_BR ][ i ] = BRObj;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP0 ][ i ] = SPObj0;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP1 ][ i ] = SPObj1;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SP2 ][ i ] = SPObj2;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_3 ][ i ] = null;
        this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_4 ][ i ] = null;
      }
    } else if ( ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_MULTI_SELECT ) ||
                ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_SINGLE_SELECT ) ) {

      /* Build the multi-select object.  Check to see if this column displays
         a different one for filtering (like Status in Ad Inquiry) */
      var filterColumn = null;
      var colNumber = 0;

      if ( sortableColumn.filterListCol != "" ) {
        filterColumn = this.getSortable2ColumnById( sortableColumn.filterListCol );
        colNumber = filterColumn.cNumber;
      }
      else {
        filterColumn = sortableColumn;
        colNumber = sortableColumn.cNumber;
      }

      var url = this.prepareXMLRequest( "list", 
                                       { "col": colNumber,
                                         "dir": true,
                                         "name": filterColumn.cId },
                                        null );

      var jsonObj = JSONObject;

      var populateFilterData = function( obj ) {
        var selectedItems = jsonObj ? map( function( f ) { return { "value": f[1], "display": f[2] } }, jsonObj[ "filters" ] ) : [];
        var selectObj = self.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ 0 ];
      
        forEach( obj,
                 function( item ) {
                   addOption( selectObj, item.value, item.display );
                 } );

        forEach( selectedItems,
                 function( item ) {
                   var option = findOptionByValue( selectObj.options, item.value );
                   if ( option ) {
                     option.selected = true;
                   }
                   else {
                     addOption( selectObj, item.value, item.display );
                     option = findOptionByValue( selectObj.options, item.value );
                     option.selected = true;
                   }
                 } );
      };

      // Build the select object pull-down to select equality type.
      var select1Obj;
      if ( sortableColumn.cFilterType == SORTABLE.FILTER_TYPE_MULTI_SELECT ) {
        select1Obj = SELECT( { 'multiple': 'multiple',
                               'size':     '10' }, null );
      } else {
        select1Obj = SELECT( {}, null );
      }

      // Save off all objects for latter removal.
      this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE ][ 0 ] = select1Obj;

      var isNegative = !isUndefinedOrNull(jsonObj) && 
                       !isUndefinedOrNull(jsonObj.filters) && 
                       jsonObj.filters[0][0] == "neq";

      
      var radioId = "isnegative_" + new Date().getTime();

      var negateRadioTrue = INPUT( { 'type'  : 'radio',
                                     'value' : 'true',
                                     'id'    : radioId + "_true",
                                     'name'  : 'isnegative' }, null); 

      var negateRadioFalse = INPUT( { 'type'  : 'radio',
                                      'value' : 'false',
                                      'id'    : radioId + "_false",
                                      'name'  : 'isnegative' }, null);

      var showAllCheckbox = INPUT( { 'type' : 'checkbox',
                                     'value' : 'false',
                                     'id' : 'showAllCheck',
                                     'name' : filterColumn.cId }, null );

      setNodeAttribute((isNegative) ? negateRadioTrue : negateRadioFalse, "checked", "checked");
      ((isNegative) ? negateRadioTrue : negateRadioFalse).checked = true;

      var labelTrue = LABEL({'for' : radioId + "_true"}, "Exclude");
      var labelFalse = LABEL({'for' : radioId + "_false"}, "Include");
      var labelShowAll = LABEL( { 'for' : 'showAllCheck' }, "Show All" );
      forEach([labelTrue,labelFalse,labelShowAll], function(l) { setStyle(l, {"margin":"3px"}); });

      this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_COMPARE_NOT ] = negateRadioTrue;

      filterSpan = DIV(null, negateRadioFalse, labelFalse, negateRadioTrue, labelTrue, BR(), showAllCheckbox, labelShowAll, BR(), select1Obj);
      
      var myDeferred;

      if ( ! isUndefinedOrNull( sortableColumn.filterDataCallback ) ) {
        myDeferred = succeed();
        myDeferred.addCallback( partial( sortableColumn.filterDataCallback, sortableColumn ) );
      }
      else if ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_BOOLEAN ) {
        /* Boolean columns we can handle automatically */
        myDeferred = succeed();
        myDeferred.addCallback( function() {
          return [ { "value": "false", "display": "False" },
                   { "value": "true", "display": "True"  } ];
        } );
      }
      else {
        var xmlHttp = getXMLHttpRequest();
        xmlHttp.onerror = ajaxError;
        xmlHttp.open( "GET", url, true );
        myDeferred = sendXMLHttpRequest( xmlHttp );

        if ( ! this.tSearchParams ) {
          myDeferred.addCallback( function( xmlHttp ) {
            // Parse the responseText as JSON before passing it to remaining callbacks
            return JSON.parse( xmlHttp.responseText );
          });
          myDeferred.addCallback( this.datatableFromJSONRequest );
          myDeferred.addCallback( function( dataObj ) {
            var obj = [];
            
            if ( dataObj.Table.NumberOfRows > 0 ) {
              dataObj.Table.Record = ( typeOf( dataObj.Table.Record ) !== "array" ) ?
                [ dataObj.Table.Record ] : dataObj.Table.Record;
              for ( var i = 0; i < dataObj.Table.Record.length; i++ ) {
                obj.push ( { "value": dataObj.Table.Record[i].FILTERSORT,
                             "display": dataObj.Table.Record[i].FILTERDISPLAY } );
              }
            }
            return obj;
          } );
        }
        else {
          myDeferred.addCallback( function( xmlHttp ) {
            var obj = [],
                csvObj = csvParse( xmlHttp.responseText );

            forEach( csvObj.slice( 1 ),
                     function( row ) {
                       forEach( row, function( elem ) {
                         obj.push( { "value": elem,
                                     "display": elem } );
                       } );
                     } );
            return obj;
          } );
        }
      }
      myDeferred.addCallback( populateFilterData );
    }

    // Build the links to save, cancel and apply the select filters.

    // Save off the filterSpan for this column.
    this.tWorkingObjs[ SORTABLE.WORKING_OBJ_FILTER_SPAN_1 ] = filterSpan;

    // Append all nodes to the filterDiv.
    appendChildNodes( filterDiv, filterSpan, BR(),
                      document.createTextNode( "\u00a0\u00a0" ) );

    var title = sortableColumn.cTitle + " " + getLangString("FILTERS");
    var clearFunc = function() { self.clearFilterEdit( sortableColumn.cId ); };
    appendChildNodes( mainDiv, filterDiv );
    this.flDiv = new FloatingDiv( filterDiv,
                                  { "title": title,
                                    "saveText": getLangString( "APPLY" ),
                                    "saveFunc": this.applyFilterEdit,
                                    "cancelText": getLangString( "CLOSE" ),
                                    "cancelFunc": this.cancelFilterEdit,
                                    "disposeOnHide": true,
                                    "additionalButtons": [
                                      { "key": "clear",
                                        "caption": getLangString( "CLEAR" ),
                                        "func": clearFunc,
                                        "isDefault": false,
                                        "isCancel": false } ] } );

    if ( !isUndefinedOrNull( $( "showAllCheck" ) ) ) {
      this.oldFilterVals = [];
      this.newFilterVals = [];
      $( "showAllCheck" ).onchange = this.toggleShowAll;
      if ( $( "showAllCheck" ).name == "STATUSCODE" || $( "showAllCheck" ).name == "GANGID" ) {
        $( "showAllCheck" ).disabled = true;
      }
    }

    this.flDiv.show();
  },

  "toggleShowAll": function () {
    var selectObj = $( "showAllCheck" ).parentElement.getElementsByTagName( "select" )[ 0 ];

    if ( ! $( "showAllCheck" ).checked ) {
      if ( !jQuery.isEmptyObject ( this.oldFilterVals ) ) {
        var selectLength = selectObj.children.length;
        for ( var i = 0; i < selectLength; i++ ) {
          selectObj.removeChild( selectObj.firstChild );
        }

        this.oldFilterVals.forEach( function ( e ) {
          selectObj.append( e );
        } );
      }
      return;
    }

    if ( $( "showAllCheck" ).checked ) {
      if ( !jQuery.isEmptyObject( this.newFilterVals ) ) {
        var selectLength = selectObj.children.length;
        for ( var i = 0; i < selectLength; i++ ) {
          selectObj.removeChild( selectObj.firstChild );
        }

        this.newFilterVals.forEach( function ( e ) {
          selectObj.append( e );
        } );

        return;
      }
    }

    var thisThis = this;
    var mCallback = function ( r ) {
      var optionJSON = r.options;

      jQuery.extend( thisThis.oldFilterVals, selectObj.children, true );
      for ( var i = 0; i < thisThis.oldFilterVals.length; i++ ) {
        selectObj.removeChild( selectObj.firstChild );
      }

      var keys = Object.keys( optionJSON );
      var vals = Object.values( optionJSON );

      for ( var i = 0; i < keys.length; i++ ) {
        var option = OPTION( { "value": keys[ i ] } );
        option.innerHTML = vals[ i ];
        selectObj.appendChild( option );
      }

      jQuery.extend( thisThis.newFilterVals, selectObj.children, true );
    }

    var mErrorCallback = function ( r ) {
      console.error( r );
    }

    mKeys = [ "showAll", "currentColumn" ];
    mVals = [ $( "showAllCheck" ).checked, $( "showAllCheck" ).name ];

    genericAjaxRequest( "toggle_multi_values", false, mCallback, mErrorCallback, mKeys, mVals, null, null, null, null, null, true );
  },

  "onFilterClick": function ( columnId, supported ) {
    var self = this;

    return function ( e ) {
      if ( ! isUndefinedOrNull( self.sortTimer ) ) {
        clearTimeout( self.sortTimer );
        self.sortTimer = null;
      }

      if ( isUndefinedOrNull( e ) )
      { e = window.event; }

      var sortableColumn = self.getSortable2ColumnById( columnId );
      if ( sortableColumn && supported ) {
        self.writeSortableMessage( null, "" );
        self.writeFilter( sortableColumn, e.clientX, e.clientY );
      }
      else {
        self.writeSortableMessage( "WARNING_NO_FILTER_MSG", sortableColumn.cTitle );
      }
    };
  },

  "getLuceneSortStr": function() {
    var luceneSortStr = "fieldname,direction\n";
    if ( isEmpty( this.tSortArr ) ) {
      var col = this.getSortable2Column( "cNumber", this.tSortColumn );
      if ( ! isUndefinedOrNull( col ) ) {
        var sortDir = this.tSortDirection ? "asc" : "desc";
        luceneSortStr += col.sortColumn + "," + sortDir + "\n";
      }
    }
    else {
      forEach( this.tSortArr,
               function( s ) {
                 luceneSortStr += s.columnId + "," + s.direction + "\n";
               } );
    }
    return luceneSortStr;
  },

  "getSortColIndex": function( columnId ) {
    return findValue( map( compose( itemgetter( "columnId" ) ),
                           this.tSortArr ), columnId );
  },

  /**
   * Generic function to find a sortable2column based off a certain property
   * and value of that property.
   */
  "getSortable2Column": function( prop, value ) {
    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    var i = findValue( map( itemgetter( prop ), lColumns ), value );
    return ( i != -1 ) ? lColumns[i] : null;
  },

  "getSortable2ColumnById": function( id ) {
    return this.getSortable2Column( "cId", id );
  },

  "clearSorts": function() {
    this.tSortArr = [];
  },

  "updateSortArray": function( columnNumber, columnId, dir ) {
    var firstElem = this.tSortArr[0];
    var direction = "asc";
    var colObj = { "col": columnNumber,
                   "dir": true,
                   "name": columnId };
    var index = this.getSortColIndex( columnId );

    if ( ! isUndefinedOrNull( dir ) ) {
      direction = dir ? "asc" : "desc";
    }

    if ( firstElem && firstElem.columnId == columnId ) {
      // We got passed a specific sort direction
      if ( ! isUndefinedOrNull( dir ) ) {
        firstElem.direction = direction;
      }
      else {
        // Reverse the direction if it's the first element
        firstElem.direction = ( firstElem.direction == "asc" ) ? "desc" : "asc";
      }
    }
    else if ( index != -1 ) {
      // In the list, move to the front and reset to ascending.
      var sortColObj = this.tSortArr.splice( index, 1 )[0];
      sortColObj["direction"] = direction;
      this.tSortArr.unshift( sortColObj );
      firstElem = sortColObj;
    }
    else {
      this.tSortArr.unshift( { "columnId": columnId,
                               "columnNumber": columnNumber,
                               "direction": direction } );
      firstElem = this.tSortArr[0];
    }

    colObj["dir"] = ( firstElem.direction == "asc" );

    return colObj;
  },

  "onSortClick": function ( columnNumber, columnId, supported, dir ) {
    var self = this;
    var sortableColumn = self.getSortable2ColumnById( columnId );
    var sortColId = sortableColumn ? sortableColumn.sortColumn : columnId;

    return function () {
      if ( self.tSuspendOps ) return;

      if ( supported ) {
        if ( ! isUndefinedOrNull( self.sortTimer ) ) {
          clearTimeout( self.sortTimer );
          self.sortTimer = null;
        }

        if ( isUndefinedOrNull( self.sortTimer ) )
          self.sortTimer = setTimeout( function() {
            var colObj = self.updateSortArray( columnNumber, sortColId, dir );
                  var url = self.prepareXMLRequest( "sort", colObj, null );
                  self.loadFromURL2( url, "" );
              }, 500 );
      }
      else {
        if ( sortableColumn ) {
          self.writeSortableMessage( "WARNING_NO_SORT_MSG",
                                     sortableColumn.cTitle );

        }
      }
    };
  },

  /**
   * Go through all sortable columns, find any that are checkboxes and clear
   * the "select all" box.
   */
  "setSelectAllUnchecked": function() {
    var i, selectall_id, sortableColumn;

    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

    for ( i = 0 ; i < lColCount; i++ ) {
      sortableColumn = lColumns[i];
      if ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_CHECKBOX ) {
        selectall_id = $( SORTABLE.SELECT_ALL_PREFIX + sortableColumn.cId );
        if ( ! isUndefinedOrNull( selectall_id ) ) {
          selectall_id.checked = false;
          this.tSelectAll[sortableColumn.cId] = false;
        }
      }
    }
  },

  "toggleInputValue": function ( columnId, keyValue ) {
    var sortableColumn = this.getSortable2ColumnById( columnId );

    if ( sortableColumn != null ) {
      if ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_RADIO ) {
        if ( ( isUndefinedOrNull( this.tChangedRadio[ columnId ] ) ) ||
             ( this.tChangedRadio[ columnId ] != keyValue ) ) {
          this.tChangedRadio[ columnId ] = keyValue;
        }

      } else if ( sortableColumn.cType == SORTABLE.COLUMN_TYPE_CHECKBOX ) {
        var input_id = "input_" + columnId + "_" + keyValue;
        var rowId = $(input_id).value.toString();

        if ( this.tUseMaintainChecksMethod ) {
          //
          // If the row is already in the changed from pre-checked list,
          // toggle its selected state.
          // 
          // Otherwise, its current individual state is its pre-checked 
          // state, except if there has been a "select all" or "unselect
          // all", in which case the individual state is always false.
          // Add the row to the changed from pre-checked list, setting
          // its state in that list by toggling its current individual
          // state.
          //
          if ( ! this.toggleRowInChangedFromPreChecked( rowId ) ) {
            var currentState = ( this.tSelectAllStatus == "nosetall" ) &&
                               this.isRowPreChecked( rowId );
            this.markRowAsChangedFromPreChecked( rowId, ( ! currentState ) );
          }

        } else {
          if ( ! this.tChangedCheckbox[ columnId ] ) {
            this.tChangedCheckbox[ columnId ] = new Array();
          }

          if ( isUndefinedOrNull( this.tChangedCheckbox[ columnId ][ keyValue ] ) ) {
            this.tChangedCheckbox[ columnId ][ keyValue ] = true;
          } else {
            delete( this.tChangedCheckbox[ columnId ][ keyValue ] );
          }

          if ( $(input_id).checked ) {
            this.addItemToCheckedList( columnId, rowId );
          } else {
            this.removeItemFromCheckedList( columnId, rowId );
          }
        }

        // Once the user changes anything, we turn off select all if it was set
        this.tSelectAll[ columnId ] = false;
        var selectall_id = $(SORTABLE.SELECT_ALL_PREFIX + sortableColumn.cId);
        if ( ! isUndefinedOrNull( selectall_id ) )
          selectall_id.checked = false;

        this.updateToolbarActions();
      }
    }
  },

  "getRadioChanges": function ( columnId ) {
    var keys = new Array();
    var vals = new Array();

    if ( isUndefinedOrNull( this.tChangedRadio[ columnId ] ) )
      return "";

    keys.push( columnId );
    vals.push( this.tChangedRadio[ columnId ] );

    return queryString( keys, vals );
  },

  //
  // Return an object containing two arrays, one with keys and one with
  // vals.  The key/val pairs consist of:
  //
  //     the value of tSelectAllStatus
  //  One pair for each row of tChangedFromPreChecked; each key/val pair is:
  //    "rowid_" + the 'rowId' of the row/the effective state of the row
  //
  // The effective state of a row is the boolean string value of its 'selected'
  // value, negated for a "select all".
  //
  // A boolean string value is "true" for a true boolean, "false" for a false
  // boolean.
  //
  "getMaintainChecksStatus": function( returnOnlyIfChanged ) {
    if ( isUndefinedOrNull( returnOnlyIfChanged ) ) returnOnlyIfChanged = true;

    if ( ! this.tUseMaintainChecksMethod ) {
      console.log( "getCheckboxChanges should be used only with UseMaintainChecksMethod" );
    }

    var kv = { keys: [], vals: [] };
    forEach( this.tChangedFromPreChecked,
             function( listObj ) {
               // 
               // To keep the size of the formula table that is loaded from
               // the request to a minimum, don't add a row if the formula can
               // derive its effective state from the value of SelectAllStatus.
               //
               // Thus if SelectAllStatus is "selectall", don't add any row
               // whose effective checked state is true; if SelectAllStatus
               // is "unselectall", don't add any row whose effective checked
               // state is false.  Note that in both these cases, the selected
               // state in the list is false.
               // 
               if ( ( this.tSelectAllStatus == "nosetall" ) || listObj.selected ) {
                 var isChecked = ( this.tSelectAllStatus == "selectall" )
                                            ? ( ! listObj.selected )
                                            : listObj.selected;
                 kv.keys.push( "rowid_" + listObj.rowId );
                 kv.vals.push( isChecked ? "true" : "false" );
               }
             },
             this
           );
    //
    // If there are no items in ChangedFromPrechecked and there's never been
    // a "select all" or "unselect all", nothing has changed.  Otherwise,
    // add the "selectallstatus" to the arrays.  The formula code uses that
    // to determine the state of rows not in ChangedFromPrechecked.
    //
    if ( ( ! returnOnlyIfChanged ) ||
         ( kv.keys.length > 0 ) || ( this.tSelectAllStatus != "nosetall" ) ) {
      kv.keys.push( "selectallstatus" );
      kv.vals.push( this.tSelectAllStatus );
    }
    return kv;
  },

  "getCheckboxChanges": function ( columnId ) {
    var kv = { keys: [],
               vals: [] };

    if ( this.tUseMaintainChecksMethod ) {
      console.log( "getCheckboxChanges should not be used with UseMaintainChecksMethod" );
    }
    for ( var i in this.tChangedCheckbox[ columnId ] ) {
      kv.keys.push( columnId );
      kv.vals.push( i );
    }

    return kv;
  },

  "getFullCheckboxList": function () {
    var kv = { keys: [], vals: [] };
    if ( this.tUseMaintainChecksMethod ) {
      console.log( "getCheckboxChanges should not be used with UseMaintainChecksMethod" );
    }
    forEach( this.tCheckedList,
             function( checkedObj ) {
               kv.keys.push( checkedObj.columnName );
               kv.vals.push( checkedObj.value );
             } );
    return kv;
  },

  "getAllCheckboxChanges": function () {
    var kv = { keys: [],
               vals: [] };
               
    if ( this.tUseMaintainChecksMethod ) {
      console.log( "getAllCheckboxChanges should not be used with UseMaintainChecksMethod" );
    }
    for( var i in this.tChangedCheckbox ) {
      for( var j in this.tChangedCheckbox[ i ] ) {
      	kv.keys.push( i );
      	kv.vals.push( j );
      }
    }
    return kv;
  },  


  /*
    Returns a queryString for all checkbox changes in the table
  */ 
  "getAllCheckboxChangesString" : function()
  {
    if ( this.tUseMaintainChecksMethod ) {
      console.log( "getAllCheckboxChangesString should not be used with UseMaintainChecksMethod" );
    }
    var retVal = "";
    for( var i in this.tChangedCheckbox ) {
      retVal += this.getCheckboxChangesString(i);
    }
    return retVal;
  },

  /*
    Wrapper function for getCheckboxChanges.  This returns a 
    string to just add directly to the end of a URL.
  */
  "getCheckboxChangesString" : function( columnId )
  {
    if ( this.tUseMaintainChecksMethod ) {
      console.log( "getCheckboxChangesString should not be used with UseMaintainChecksMethod" );
    }
    var kv = this.getCheckboxChanges(columnId);
    return "&" + queryString( kv.keys, kv.vals );
  },

  "generateReport": function () {
    if ( ! this.tablePrefsObject.printable ||
         this.tSearchParams != null ) return;

    var showReport = function( obj ) {
      var url = constructURL( "getfile",
                              { "filename": obj["ReportName"] },
                              "", "table.pdf" );

      var tempGrowl = new GrowlMessage(
        { 'header': "Printing table...",
          'sticky': true,
          'message': "The PDF for the table below is ready to view.",
          'newwindow': true,
          'href': url } );
      tempGrowl.displayMessage();
    };

    var params = { "nav": "report",
                   "tableid": this.tId,
                   "reporttitle": this.tTitle,
                   "page": this.tCurrentPage,
                   "type": "pdf",
                   "visibleColumns[]": this.getVisibleColumnIdList()
                 };

    getJSON( this.tWorkingObjs[ "command" ], showReport, params, null, 
             this.tWorkingObjs[ "spicepl" ] );
  },

  "generateSpreadsheet": function () {
    if ( ! this.tablePrefsObject.printable ) return;

    var showReport = function( obj ) {
      var url = constructURL( "getfile",
                              { "filename": obj["ReportName"] },
                              "", "table.csv" );
      var tempGrowl = new GrowlMessage( { 'header': "Exporting table...",
                                          'sticky': true,
                                          'message': "The CSV file for the table below is ready to view.",
                                          'href': url,
                                          'download': "table.csv" } );
        tempGrowl.displayMessage();
    };

    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    var params = { "nav": "report",
                   "tableid": this.tId,
                   "reporttitle": this.tTitle,
                   "page": this.tCurrentPage,
                   "type": "csv",
                   "visibleColumns[]": this.getVisibleColumnIdList(),
                   "timezone": timezone
                 };

    if ( this.tSearchParams != null ) {
      params["lucenefilter"] = this.buildLuceneFilter();
      params["luceneSort"] = this.getLuceneSortStr();
      params = merge( params,
                      keyValsToObj( this.tSearchParams.keys,
                                    this.tSearchParams.values ) );
    }

    getJSON( this.tWorkingObjs[ "command" ], showReport, params, null,
             this.tWorkingObjs[ "spicepl" ] );
  },
  
  "editFieldOnKeyDown": function( e ) {
    var key = e.key().string;
    
    if ( key == "KEY_ENTER" ) {
      // Handle the enter key being pressed
      this.saveEditField();
    } else if ( key == "KEY_ESCAPE" ) {
      // Handle the escape key being pressed.
      this.closeEditField();
    }

    return true;
  },

  "saveEditField" : function(e)
  {
    return this.closeEditField(e, true);
  },

  "closeEditField": function (e, performSave ) {
    if ( isUndefinedOrNull(performSave) )
      { performSave = false; }

    var self = this;

    var errCallBack = function ( localReplyManager ) {
      var editableField = self.tWorkingObjs[ "editable_field" ];
      replaceChildNodes(editableField, 
                        SPAN( { 'id' : self.getEditableUniqueId(self.tWorkingObjs.key_value, self.tWorkingObjs.edit_column) },
                              self.tWorkingObjs[ "inner_html" ] ) );
      var editImg = getSpriteIMGElement("YELLOWPENCIL", getLangString("CLICKTOEDIT"));
      connect(editImg, "onclick", self.getEditFieldFunc(self.tName, 
                                                        self.tWorkingObjs.key_value, 
                                                        self.tWorkingObjs.edit_column));
      appendChildNodes(editableField, editImg);
      //editableField.onclick = self.tWorkingObjs[ "onclick" ];
      self.tWorkingObjs[ "editable_field" ] = null;

      // Prevent calling this function when we are not 
      // coming back from an AJAX response.
      if(!isUndefinedOrNull(localReplyManager))
        { defaultErrorCallback(localReplyManager); }
    };    
 
    if ( performSave && !isUndefinedOrNull($("editable_field")) )
    {
      var value = $("editable_field").value;
      var descText = value;
      if ( $("editable_field").tagName == "SELECT" ) {
      	descText = $("editable_field")[$("editable_field").selectedIndex].text;
      }
      var oldValue = this.tWorkingObjs[ "inner_html" ];
      var keyvalue = this.tWorkingObjs[ "key_value" ];
      var editColumn = this.tWorkingObjs[ "edit_column" ];
      var keyColumn = this.tWorkingObjs[ "key_column" ];
      
      var myCallBack = function ( localReplyManager ) {
        var editableField = self.tWorkingObjs[ "editable_field" ];
        replaceChildNodes(editableField, 
                          SPAN( { 'id' : self.getEditableUniqueId(self.tWorkingObjs.key_value, self.tWorkingObjs.edit_column) },
                                descText) );
        self.setColumnValue( keyvalue, editColumn, value ); 
        var editImg = getSpriteIMGElement("YELLOWPENCIL", getLangString("CLICKTOEDIT"));
        connect(editImg, "onclick", self.getEditFieldFunc(self.tName, 
                                                          self.tWorkingObjs.key_value, 
                                                          self.tWorkingObjs.edit_column));
        appendChildNodes(editableField, editImg);
        //editableField.onclick = self.tWorkingObjs[ "onclick" ];
        self.tWorkingObjs[ "editable_field" ] = null;
        self.setPriorityColors( $j( editableField ).parents( "tr" )[ 0 ]);
      };

      if ( value != oldValue )
      {
        var params = {
          "tableid": this.tId,
          "col": ( keyColumn != "" ) ? keyColumn : editColumn,
          "value": value,
          "keyvalue": keyvalue
        };

        if( isUndefinedOrNull(this.tWorkingObjs.editcommand) || 
            strip(this.tWorkingObjs.editcommand) == "" )
        {
          throw("No edit callback command specified.");
        }
        else
        {
          return postJSON( this.tWorkingObjs[ "editcommand" ], myCallBack,
                           params, null, this.tWorkingObjs[ "spicepl" ],
                           errCallBack );
        }
      }
      else {
      	errCallBack();      
        return succeed();
      }
    }
    else {
      errCallBack();
      return succeed();
    }
  },

  "setPriorityColors": function ( tr ) {
    if ( ( typeof( priorityList ) != "undefined" ) && !!tr ) {
      var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
      var lColCount = ( this.tGridView ? this.tColCountGrid : this.tColCountList );

      var priorityIndex = -1;
      for ( var i = 0; i < lColCount; i++ ) {
        var span = $j( tr ).find( "td" ).eq( i ).find( "span" )[ 0 ];
        if ( !!span ) {
          if ( span.id.includes( "column_PRIORITY" ) ) {
            var spanHTML = span.innerHTML;
            for ( var i = 0; i < priorityList.length; i++ ) {
              if ( priorityList[ i ].description == spanHTML ) {
                tr.style.backgroundColor = priorityList[ i ].color;
                break;
              }
            }

            break;
          }
        }
      }
    } else if ( typeof( sT_priority_search ) != "undefined" ) {
      var colorIdx = -1;
      $j( "#sT_priority_search tr" ).each( function ( i, el ) {
        if ( i == 0 ) {
          $j( el ).find( "th" ).each( function ( i2, el2 ) {
            if ( el2.id.trim() == "priority_search_COLOR" ) {
              colorIdx = i2;
            }
          } );
        } else if ( colorIdx != -1 ) {
          var colorTD = $j( el ).find( "td" ).eq( colorIdx );
          $( colorTD ).parents( "tr" ).css( "background-color", colorTD.html() );
        }
      } );
    }
  },

  "editField": function( keyValue, columnId ) {
    var editableField = this.tWorkingObjs[ "editable_field" ];
    var deferred = null;
    // If a field is already open, close and save it before accessing the next field
    if ( ! isUndefinedOrNull( editableField ) )
      deferred = this.saveEditField();
    else
      deferred = succeed();

    var lColumns = ( this.tGridView ? this.tColumnsGrid : this.tColumnsList );
    var colIndex = objIndexOf( lColumns,
                               "cId",
                               columnId );
    if(colIndex < 0)
      { throw("Specified edit column not found."); }

    var column = lColumns[colIndex];

    if( column.cType === SORTABLE.COLUMN_TYPE_TEXTFIELD || 
        column.cType === SORTABLE.COLUMN_TYPE_SELECTBOX )
    {
      deferred.addCallback(this.appendEditFieldInput, keyValue, columnId, column);
    }
    else if( column.cType === SORTABLE.COLUMN_TYPE_TOGGLE_ONE || 
             column.cType === SORTABLE.COLUMN_TYPE_TOGGLE_MULTIPLE )
    {
      deferred.addCallback(this.toggleField, keyValue, columnId, column);
    }
    
  },

  "toggleField" : function(keyValue, columnId, column)
  {
    // Get the cell to edit by id and save off its values
    // Generate the unique identifier of the cell being editted
    var uniqueId = this.getEditableUniqueId(keyValue, columnId);
    var editableField = document.getElementById( uniqueId );

    // Find out the current and new values
    var currVal = this.getColumnValue(keyValue, columnId) + "";
    var currImg = column.getToggleImage(currVal);
    var index = column.cValidValues.indexOf( currVal );
    if(++index >= column.cValidValues.length) { index = 0;}
    var newVal = column.cValidValues[index];
    var newImg = column.getToggleImage(newVal);

    // Send off the AJAX request to make sure this is an OK edit.
      
    var self = this;
    var myCallBack = function ( respObj ) 
    {
      if(column.cType === SORTABLE.COLUMN_TYPE_TOGGLE_ONE)
      {
        if( respObj["Toggle"] )
        {
          // Unset all other values, set our value, and redraw
          forEach(self.tData, function(d) 
            { d[columnId] = column.cValidValues[column.cValidValues.length-1]; });

          self.setColumnValue(keyValue, columnId, newVal);
          self.redrawSortableTable();
        }
      }
      else
      {
        // Here, we only need to update us, so just change the CSS class and set our value
        removeElementClass(editableField, currImg);
        addElementClass(editableField, newImg);
        self.setColumnValue(keyValue, columnId, newVal);
      }
    };

    var params = { "tableid": this.tId,
                   "col": columnId,
                   "value": newVal,
                   "keyvalue": keyValue }

    // If we're a toggle_one field, we reset all other fields 
    // to our last item (should only specify two, like [GREENFLAG,REDFLAG]
    if(column.cType === SORTABLE.COLUMN_TYPE_TOGGLE_ONE)
    {
      params["allothers"] = column.cValidValues[column.cValidValues.length-1];
    }

    return postJSON( this.tWorkingObjs[ "editcommand" ], myCallBack, params,
                     this.tWorkingObjs[ "spicepl" ] );
  },

  "appendEditFieldInput" : function(keyValue, columnId, column)
  {
    // Generate the unique identifier of the cell being editted
    var uniqueId = this.getEditableUniqueId(keyValue, columnId);

    // Get the cell to edit by id and save off its values
    var editableField = document.getElementById( uniqueId );
    this.tWorkingObjs[ "editable_field" ] = editableField.parentNode;
    this.tWorkingObjs[ "inner_html" ] = scrapeText( editableField );
    this.tWorkingObjs[ "onclick" ] = editableField.onclick;
    this.tWorkingObjs[ "edit_column" ] = columnId;
    this.tWorkingObjs[ "key_value" ] = keyValue;
    this.tWorkingObjs[ "key_column" ] = column.cKeyColumnId;

    // Build an input object text field based off the data in the current
    // cell.
    var inputObj = null;

    if(column.cType === SORTABLE.COLUMN_TYPE_TEXTFIELD)
    {
      var editLength = parseInt(column.cEditableLength, 10);
      if(editLength <= 0) { editLength = 20; }
      inputObj = INPUT( { 'id'  :      'editable_field',
                          'name':      'editable_field',
                          'type':      'text',
                          'size':      (editLength <= 25) ? editLength+5 : 30, // +5 is to account for font width issues
                          'maxlength': editLength,
                          'value':     strip( this.tWorkingObjs[ "inner_html" ] ) }, null );
  
      // Commented out because if this is connected, the blur fires before the 
      // onclick of the save/cancel button, so they become useless.  No, the
      // only way to have an input go away is to click save or cancel, or to 
      // click edit on another input., which will automatically save if you 
      // have changed the value
      //connect(inputObj, "onblur", this.closeEditField);
    }
    else if(column.cType === SORTABLE.COLUMN_TYPE_SELECTBOX)
    {
      if(column.cValidValues.length == 0)
        { throw("Invalid values specified for column."); }

      var values = column.cValidValues;
      var ids = column.cValidIDs;
 
      inputObj = SELECT( { 'id'   : 'editable_field',
                           'name' : 'editable_field' } );
      var currentVal = this.tWorkingObjs[ "inner_html" ];
      for ( var i = 0; i < ids.length; i++ ) {
      	var opt = OPTION({'value':ids[i]},values[i]);
      	if ( strip(values[i]) == strip(currentVal)) {
      	  opt.selected = true;
      	  setNodeAttribute(opt, "Selected", "selected");
      	}
      	appendChildNodes(inputObj, opt);
      }
    }
    else
    {
      throw("Invalid column type found.");
    }

    connect(inputObj, "onkeydown", this.editFieldOnKeyDown);

    var saveImg = getSpriteIMGElement("GREENCHECK", getLangString("SAVEFIELD"));
    connect(saveImg, "onclick", this.saveEditField);
    var closeImg = getSpriteIMGElement("REDX", getLangString("CANCELEDIT"));
    connect(closeImg, "onclick", this.closeEditField);

    // Prepare the fields for editing
    replaceChildNodes(this.tWorkingObjs[ "editable_field" ], inputObj, saveImg, closeImg);
    inputObj.focus();
  },

  "getEditableUniqueId" : function(keyVal, cId)
  {
    return 'scs_editable_' + keyVal + '_column_' + cId;
  },

  "getEditFieldFunc" : function(tName,key,cid)
  {
    return function()
           {
             var funcName = "editField";
             if( typeof window[tName] === "object" &&
                 typeof window[tName][funcName] === "function" )
             {
               return window[tName][funcName](key,cid);
             }
             else
             {
               throw("Invalid editing field.");
             }
           };
  },

  "buildLuceneFilter": function() {

    var formatFilterVal = function( v, staticVal ) {
      /*
       * Replace all special lucene characters, these are:
       *   + - && || ! ( ) { } [ ] ^ " ~ * ? : \
       * We also replace spaces with and if the user typed this in.
       */
      if ( staticVal ) {
        return v.replace( /(\+|-|&&|\|\||!|\(|\)|\{|\}|\[|\]|\^|"|~|\*|\?|:|\\)/g, "\\$1" ).replace( / /g, "\\ " );
      }
      else {
        return v.replace( /(\+|-|&&|\|\||!|\(|\)|\{|\}|\[|\]|\^|"|~|\?|:|\\)/g, "\\$1" ).replace( / /g, " AND " );
      }
    };

    var formatSizeVal = function( v, numLen ) {
      var n = parseFloat( v );

      var s;
      if ( ( !isUndefinedOrNull( numLen ) ) && ( numLen > 0 ) ) {
        s = n.toFixed( 0 );
      } else {
      	s = n.toFixed( 3 );
      	numLen = 10;
      }

      while ( s.length < numLen ) {
        s = "0" + s;
      }
      return s;
    }

    var formatDateVal = function( date, time, isStartDate, useTime, isRunDate ) {
      var lastSeconds = "00000000";
      if ( time == "" || useTime == "1" ) {
        if ( isStartDate ) {
          time = "00:00";
        } else {
          time = "23:59";
          lastSeconds = "59999999";
        }
      } else if ( useTime == "2" && !isStartDate ) {
        lastSeconds = "59999999";
      }

      if ( isRunDate ) {
        time = "00:00";
      }

      var convertedDate = new Date( date + "T" + time ).toISOString();
      var tempDateArray = convertedDate.split("-");
      var tempTimeArray = tempDateArray[ 2 ].split( ":" )
      var formattedDate = tempDateArray[ 0 ] + tempDateArray[ 1 ] +
        tempTimeArray[ 0 ].substr( 0, 2 ) + tempTimeArray[ 0 ].substr( 3, 2 ) +
        tempTimeArray[ 1 ] + lastSeconds;

      if ( isRunDate ) {
        formattedDate = formattedDate.substr( 0, 8 );
      }

      return formattedDate;
    }

    var columnFilters = [];
    var sizeVal = 0; 

    forEach( this.tFilters,
             function( cond ) {
               var sortableColumn = this.getSortable2ColumnById( cond.columnId );
               var columnFilter = "";
               var isRunDate = false;

               var columnId = ( sortableColumn.filterListCol != "" ) ?
                              sortableColumn.filterListCol.toLowerCase() :
                              sortableColumn.cId.toLowerCase();

               if ( cond.type == SORTABLE.FILTER_TYPE_MULTI_SELECT ) {
                 var isNegative = ( cond.filters[0][0] == "neq" ||
                                    cond.filters[0][0] == "nco" );
                 columnFilter = isNegative ? "-( " : "+( ";

                 forEach( cond.filters,
                          function( filter ) {
                            var filterVal = formatFilterVal( filter[1], true );
                            columnFilter += " " + columnId + ":(" + filterVal + ") ";
                          }, this );
                 columnFilter += " )";
               }
               else if ( ( cond.type == SORTABLE.FILTER_TYPE_SIZE ) ||
                         ( cond.type == SORTABLE.FILTER_TYPE_NUMBER ) ||
                         ( cond.type == SORTABLE.FILTER_TYPE_DATE ) ||
                         ( cond.type == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
                 forEach( cond.filters,
                          function( filter ) {
                            var minVal = formatSizeVal( 0 );
                            var maxVal = "999999.999";
                            if ( ( cond.type == SORTABLE.FILTER_TYPE_NUMBER ) ||
                                 ( cond.type == SORTABLE.FILTER_TYPE_DATE ) ||
                                 ( cond.type == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
                              sizeVal = sortableColumn.numLen;
                              minVal = formatSizeVal( 0, sizeVal );
                              maxVal = "9".repeat( sizeVal );
                            } else {
                              sizeVal = 0;
                              columnId = ( filter[4] == 1 ) ? "width" : "height";
                            }

                            if ( ( cond.type == SORTABLE.FILTER_TYPE_DATE ) || ( cond.type == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
                              if ( cond.type == SORTABLE.FILTER_TYPE_DATE ) {
                                isRunDate = true;
                              }
                              var filterVal = formatDateVal( filter[ 1 ], filter[ 5 ], true, filter[ 4 ], isRunDate );
                            } else {
                              var filterVal = formatSizeVal( formatFilterVal( filter[1], true ), sizeVal );
                            }

                            switch ( filter[0] ) {
                              case "eq": /* equal to */
                                columnFilter += columnId + ":(" + filterVal + ")";
                                break;

                              case "neq": /* not equal to */
                                columnFilter += "-" + columnId + ":(" + filterVal + ")";
                                break;

                              case "lt": /* less than */
                                if ( ( cond.type == SORTABLE.FILTER_TYPE_DATE ) || ( cond.type == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
                                  columnFilter += columnId + ":{" + ( isRunDate ? "00000000" : "00000000000000000000" ) + " " + filterVal + "}";
                                } else {
                                  columnFilter += columnId + ":{" + minVal + " " + filterVal + "}";
                                }
                                break;

                              case "le": /* less than or equal to */
                                columnFilter += columnId + ":[" + minVal + " " + filterVal + "]";
                                break;
  
                              case "gt": /* greater than */
                                if ( ( cond.type == SORTABLE.FILTER_TYPE_DATE ) || ( cond.type == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
                                  columnFilter += columnId + ":{" + filterVal + " " + ( isRunDate ? "99999999" : "99999999999999999999" ) + "}";
                                } else {
                                  columnFilter += columnId + ":{" + filterVal + " " + maxVal + "}";
                                }
                                break;

                              case "ge": /* greater than or equal to */
                                columnFilter += columnId + ":[" + filterVal + " " + maxVal + "]";
                                break;

                              case "is": /* is on this date */
                              case "nd": /* is not on this date */
                                if ( filter[0] == "nd" ) {
                                  columnFilter += "-";
                                }
                                var formattedVar = formatDateVal( filter[1], filter[5], false, filter[4], isRunDate );
                                columnFilter += columnId + ":[" + filterVal + " " + formattedVar + "]";
                                break;

                              case "bt": /* between */
                              case "ot": /* outside */
                                if ( filter[0] == "ot" ) {
                                  columnFilter += "-";
                                }
                                if ( ( cond.type == SORTABLE.FILTER_TYPE_DATE ) || ( cond.type == SORTABLE.FILTER_TYPE_TIMESTAMP ) ) {
                                  var formattedVar = formatDateVal( filter[ 2 ], filter[ 6 ], false, filter[ 4 ], isRunDate );
                                } else {
                                  var formattedVar = formatSizeVal( formatFilterVal( filter[2], true ), sizeVal );
                                }
                                columnFilter += columnId + ":[" + filterVal + " " + formattedVar + "]";
                                break;
                            }
                            columnFilter += " ";
                            columnFilter += ( filter[3] != "end" ) ?
                              filter[3].toUpperCase() : "";
                            columnFilter += " ";
                          }
                        );
               }
               else {
               	 var andFilter = "";
               	 var filterFound = false;
               	 var negativeOr = "";
               	 var positiveOr = "";
               	 var prevCond = "";

                 forEach( cond.filters,
                          function( filter ) {
                            var isNegative = ( filter[0] == "neq" ||
                                               filter[0] == "nco" );
                            var filterVal = formatFilterVal( filter[1], false );

                            if ( ( filter[3].toUpperCase() == "AND" ) ||
                                 ( ( filter[3].toUpperCase() == "END" ) && ( prevCond == "AND" ) ) ||
                                 ( ( filter[3].toUpperCase() == "END" ) && ( prevCond == "" ) ) ) {
                              if ( andFilter != "" ) { andFilter += "AND "; }
                              andFilter += isNegative ? " -" : " +";
                              andFilter += columnId + ":(" + filterVal + ") ";
                            }

                            if ( ( filter[3].toUpperCase() == "OR" ) ||
                                 ( ( filter[3].toUpperCase() == "END" ) && ( prevCond == "OR" ) ) ||
                                 ( ( filter[3].toUpperCase() == "END" ) && ( prevCond == "" ) ) ) {
                              if ( !isNegative ) {
                              	if ( positiveOr != "" ) { positiveOr += " OR "; }
                              	positiveOr += filterVal;
                              } else {
                              	if ( negativeOr != "" ) { negativeOr += " OR "; }
                              	negativeOr += filterVal;
                              }
                            }

                            if ( filter[3].toUpperCase() == "END" ) {
                              if ( andFilter != "" ) {
                                filterFound = true;
                                columnFilter += andFilter;
                              }

                              if ( positiveOr != "" ) {
                              	if ( filterFound ) {
                              	  columnFilter += " AND";
                              	}
                              	filterFound = true;
                                columnFilter += " +" + columnId + ":(" + positiveOr + ") ";
                              }

                              if ( negativeOr != "" ) {
                              	if ( filterFound ) {
                              	  columnFilter += " AND";
                              	}
                              	filterFound = true;
                                columnFilter += " -" + columnId + ":(" + negativeOr + ") ";
                              }
                            }
                            prevCond = filter[3].toUpperCase();
                          }, this );
               }
               columnFilters.push( columnFilter );
             }, this );
    return columnFilters.join( " AND " );
  },

  "buildFilterBlob": function() {
    var filter_blob = "`r\nv2\n";
    var i;
    var j;
    var cond;
    var filter;
    var sortableColumn;

    for ( i = this.tFilters.length - 1; i >= 0; i-- ) {
      cond = this.tFilters[i];
      sortableColumn = this.getSortable2ColumnById( cond.columnId );

      switch ( cond.type ) {

        case SORTABLE.FILTER_TYPE_DATE:
        case SORTABLE.FILTER_TYPE_TIMESTAMP:
        case SORTABLE.FILTER_TYPE_NUMBER:
        case SORTABLE.FILTER_TYPE_SIZE:
          for (  j = cond.filters.length - 1; j >= 0; j-- ) {
            filter = cond.filters[j];

            if ( cond.type == SORTABLE.FILTER_TYPE_SIZE ) {
              var searchCol = ( filter[4] == 1 ) ? "WIDTH" : "HEIGHT";
              sortableColumn = this.getSortable2ColumnById( searchCol );
              if ( !sortableColumn )
                continue;
            }
            filter_blob += ( sortableColumn.useSortCol ? "sc:" : "dc:" ) + sortableColumn.cNumber + "\n";

            switch ( filter[0] ) {
              case "eq": /* equal to */
              case "neq": /* not equal to */
                filter_blob += "vl:" + filter[1];
                break;
              case "lt": /* less than */
                filter_blob += "hv:" + filter[1] + "\n";
                filter_blob += "hi:N";
                break;
              case "le": /* less than or eqal to */
                filter_blob += "hv:" + filter[1];
                break;
              case "gt": /* greater than */
                filter_blob += "lv:" + filter[1] + "\n";
                filter_blob += "li:N";
                break;
              case "ge": /* greater than or equal to */
                filter_blob += "lv:" + filter[1];
                break;
              case "bt": /* between */
              case "ot": /* outside */
                filter_blob += "lv:" + filter[1] + "\n";
                filter_blob += "hv:" + filter[2];
                break;
            }
            filter_blob += "\n" + ( ( filter[3] != "end" ) ? "end\n" : "" ) +
                           filter[3] + "\n";
            if ( ( filter[0] == "neq" ) || ( filter[0] == "ot" ) )
              filter_blob += "not\n";
          }
          break;

        case SORTABLE.FILTER_TYPE_STRING:
        case SORTABLE.FILTER_TYPE_SINGLE_SELECT:
        case SORTABLE.FILTER_TYPE_MULTI_SELECT:
          if ( sortableColumn.filterListCol != "" ) {
            sortableColumn = this.getSortable2ColumnById( cond.columnId );
          }

          for ( j = cond.filters.length - 1; j >= 0; j-- ) {
            filter = cond.filters[j];
            filter_blob += ( sortableColumn.useSortCol ? "sc:" : "dc:" ) + sortableColumn.cNumber + "\n";
            filter_blob += "vl:";
            var filter_val = filter[1];

            switch ( filter[0] ) {
              case "eq": /* equal to */
              case "neq": /* not equal to */
                filter_blob +=  filter_val;
                break;

              case "co": /* Contains */
              case "nco": /* Does not contain */
                filter_blob += "*" + filter_val + "*";
                break;
            }
            filter_blob += "\ncs:N\n"; /* Always case-insensitive for now */

            filter_blob += "end\n";

            if ( ( filter[0] == "nco" ) || ( filter[0] == "neq" ) )
              filter_blob += "not\n";

            filter_blob += (filter[3] != "end") ? (filter[3] + "\n") : "";
          }

          break;
      }
      filter_blob += ( i != this.tFilters.length - 1 ) ? "and\n" : "";
    }
  filter_blob += "`\n";

  return  ( this.tFilters.length > 0 ) ? filter_blob : "";
  },

  "unhighlightRows" : function()
  {
    this.highlightRow(null,null,null,true,true);
  },

  "highlightRow" : function(columnId, value, color, refresh, unhighlight)
  {
    var n = isUndefinedOrNull;
    if(n(unhighlight)) { unhighlight = false; }
    if(n(refresh)) { refresh = false; }
    var didSomething = false;
    forEach(this.tData, function(r)
    {
      if((r[columnId] == value) && (r.color != color)) 
      { 
        r.color = color; 
        didSomething = true;
      }
      else if( unhighlight && !n(r.color) )
      {
        r.color = null;
        didSomething = true;
      }
    });
    
    if(refresh && didSomething) { this.redrawSortableTable(); }
  },

  "toolbarActionFunc": function( e ) {
    this.tbC( e, this.getFullCheckboxList() );
  },

  //
  // Update the enabled state of toolbar actions that depend on how many rows
  // are checked.  For the "maintain checks" method, the JavaScript doesn't
  // know about pre-checked rows in the back-end table if it has never seen
  // those rows, so we set every active toolbar button to enabled.
  //
  "updateToolbarActions": function() {
    var numSelected = this.tCheckedList.length;
    if ( !isUndefinedOrNull( this.tbM ) ) {
      forEach( this.tbA,
               function( a ) {
                 var button = this.tbM.getChild( a.ID );
                 var enabled = ( a.ENABLED &&
                                 ( this.tUseMaintainChecksMethod ||
                                   ( ( numSelected >= a.MIN ) &&
                                     ( ( a.MAX == 0 ) ||
                                       ( numSelected <= a.MAX ) ) ) ) );
                 button.setEnabled( enabled );
               }, this );
    }
  },

  "getToolbarElement": function() {
    return $( this.tId + "_toolbar" );
  },

  "setToolbar": function( toolbar, actions, callback ) {
    this.tbM = toolbar;
    this.tbA = actions;
    this.tbC = callback;
  },

  "setFooterOptions": function( iconSize, showSubToolbar ) {
    this.footerIconSize = iconSize;
    this.showFooterSubToolbar = showSubToolbar;
  }

};

mouseOverFunc = function () {
  addElementClass( this, "over" );
};

mouseOutFunc = function () {
  removeElementClass( this, "over" );
};
